@font-face {
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 600;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff") format("woff");
}

@font-face {
  font-family: OpenSans, sans-serif;
  font-style: bold;
  font-weight: 700;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff");
}

.discussion-thread .discussion-posts .discussion-react-form, .discussion-thread .discussion-posts .discussion-report-form {
  margin-left: 150px;
  padding-bottom: 8px;
  display: none;
}

.article-gallery .gallery-info a.gallery-link, .article-gallery .gallery-info a.gallery-link .gallery-photos-caption {
  color: #303543;
  text-decoration: none;
}

.bg-color-dark {
  background-color: #303543;
}

.top-line {
  border-top: 1px solid #f1f1f6;
  height: 37px;
}

.promo-big-article {
  margin-bottom: 7px;
  position: relative;
}

.promo-big-bottom-line {
  height: 1px;
  margin: 0 18.5px;
  position: relative;
}

.title-section {
  margin-top: 30px;
}

.color-green .promo-big-bottom-line {
  background-color: #9fbc46;
}

.color-blue .promo-big-bottom-line {
  background-color: #4fbfe9;
}

.color-orange .promo-big-bottom-line {
  background-color: #dda41a;
}

.color-purple .promo-big-bottom-line {
  background-color: #ac66ee;
}

.twelve-col .breadcrumbs {
  margin-top: 10px;
  margin-bottom: 23px;
}

.article-medium-listing {
  border-top: 1px solid #f1f1f6;
  margin-top: 22px;
}

.box404 {
  text-align: center;
  margin-top: 67px;
}

.box404 .dataimg-404 {
  width: 520px;
  height: 206px;
  margin: 0 auto;
}

.box404 h3 {
  margin: 50px 0 30px;
  font-family: OpenSans, sans-serif;
  font-size: 28px;
}

.box404 .text404 {
  width: 530px;
  margin: 0 auto;
  font-family: OpenSans, sans-serif;
  font-size: 20px;
  line-height: 27px;
}

.box404 a {
  margin-top: 70px;
  display: inline-block;
}

.discussion-thread h2 {
  margin-top: 25px;
  margin-bottom: 40px;
}

.discussion-thread p {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.discussion-thread .logged-user-name {
  border-top: 1px solid #f1f1f6;
  border-bottom: 1px solid #f1f1f6;
  margin: 28px 0;
  padding: 25px 0;
}

.discussion-thread .discussion-posts {
  margin-top: 20px;
}

.discussion-thread .discussion-posts .discussion-react-form, .discussion-thread .discussion-posts .discussion-report-form {
  margin-left: 150px;
  padding-bottom: 8px;
  display: none;
}

.discussion-thread .discussion-posts .discussion-report-form label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
}

.discussion-thread .discussion-posts .discussion-report-form textarea {
  width: 310px;
  height: 100px;
}

.discussion-thread .discussion-posts .discussion-report-form input {
  margin-top: 5px;
  padding: 6px 46px;
}

.discussion-thread .discussion-login-box {
  padding-top: 35px;
  padding-bottom: 20px;
  overflow: auto;
}

.discussion-thread .discussion-login-box a {
  float: left;
  text-transform: uppercase;
}

.article-discussion-perex {
  margin-top: 36px;
  font-size: 17px;
  font-weight: bold;
}

.article-gallery {
  border-bottom: 1px solid #f1f1f6;
  min-height: 105px;
  position: relative;
}

.article-gallery .list-gallery {
  width: 520px;
}

.article-gallery .gallery-info {
  width: 95px;
}

.article-gallery .gallery-info a.gallery-link, .article-gallery .gallery-info a.gallery-link .gallery-photos-caption {
  color: #303543;
  text-decoration: none;
}

.in-article-gallery {
  border-top: 1px solid #f1f1f6;
  border-bottom: 1px solid #f1f1f6;
  width: calc(100% + 50px);
  position: relative;
  left: -50px;
}

.in-article-gallery .gallery-info {
  width: 95px;
}

.gallery-info {
  text-transform: uppercase;
  color: #303543;
  padding-top: 5px;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 0;
}

.gallery-info a:hover .gallery-photos-caption {
  text-decoration: underline;
}

.gallery-info span {
  color: #00aeef;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 5px;
  font-size: 35px;
  display: block;
}

.gallery-info .gallery-photos-caption {
  color: #303543;
  position: relative;
}

.gallery-info .gallery-photos-caption .dataimg-arrow-right {
  width: 10px;
  height: 16px;
  position: absolute;
  bottom: -.75px;
  right: 0;
}

.gallery-info .gallery-photos-caption .dataimg-arrow-right svg path {
  fill: #00aeef;
}

.no-keywords {
  margin-bottom: 40px;
}

.discussion-article-info .category-sticker {
  margin-bottom: 20px;
}

.discussion-article-info .discussion-article-link:hover h2 {
  color: #656c81;
  text-decoration: underline;
  transition: color .3s;
}

.article-comments-mourrison {
  margin-top: 50px;
}

.ad-skyscrapper {
  padding-top: 23px;
}

.ad-skyscrapper2 {
  padding-top: 57px;
}

.top-site .leader-box .leader-board table {
  background-color: rgba(0, 0, 0, 0);
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  clear: both;
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  cursor: pointer;
}

html input[type="button"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  color: #303543;
  overflow-anchor: none;
  background: #fff;
  height: 100%;
  font-family: arial;
}

* {
  box-sizing: border-box;
}

a {
  color: #303543;
  text-decoration: none;
}

img, input {
  max-width: 100%;
}

body {
  background-color: #fff;
  font-family: arial;
}

body.bg-color-dark {
  padding-top: 62px;
}

.wrapper {
  background-color: #fff;
}

h1 {
  color: #303543;
  font-family: opensans, arial, sans-serif;
  font-size: 35px;
  font-weight: bold;
}

h2 {
  color: #303543;
  font-family: opensans, arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

h6 {
  color: #303543;
  text-transform: uppercase;
  text-align: left;
  font-family: opensans, arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
}

p {
  margin: 0 0 15px;
  line-height: 1.4;
}

p:last-of-type {
  margin: 0;
}

.wrapper {
  text-align: left;
  width: 1080px;
  margin: 0 auto;
}

.wrapper.with-bg {
  background-color: #fff;
}

.wrapper:after, .wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.inner-wrapper {
  clear: both;
  width: 960px;
  margin: 0 auto;
  position: relative;
}

.inner-wrapper:after, .inner-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.one-col {
  float: left;
  width: 8.33333%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.two-col {
  float: left;
  width: 16.6667%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.three-col {
  float: left;
  width: 25%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.four-col {
  float: left;
  width: 33.3333%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.five-col {
  float: left;
  width: 41.6667%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.six-col {
  float: left;
  width: 50%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.seven-col {
  float: left;
  width: 58.3333%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.eight-col {
  float: left;
  width: 66.6667%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.nine-col {
  float: left;
  width: 75%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.ten-col {
  float: left;
  width: 83.3333%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.eleven-col {
  float: left;
  width: 91.6667%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.twelve-col {
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.form-field label {
  margin-bottom: 11px;
  font-weight: bold;
  display: block;
}

.form-field input, .form-field textarea {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  padding: 4.5px 5px;
  display: block;
}

.form-field input[type="checkbox"] {
  display: none;
}

.form-field.with-error input, .form-field.with-error textarea {
  color: #ec058d;
  border: 1px solid #ec058d;
}

.search-form-field input {
  color: #a7a7c2;
  border: 0;
  border-bottom: 1px solid #f1f1f6;
  padding: 10px 30px 9px 8px;
}

.subscription-field input, .subscription-field textarea {
  color: #8796a5;
  border: 1px solid #dbe1e7;
  border-radius: 10px;
  outline: none;
  padding: 8px 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.subscription-field input:disabled, .subscription-field textarea:disabled {
  background-color: #f0f0f0;
}

.subscription-field input.with-error, .subscription-field textarea.with-error {
  color: #ec058d;
  border: 1px solid #ec058d;
}

.calendar-field input {
  background-image: url("/images/abicko/dist/svg/calendar.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  padding-right: 35px;
}

.subscription-field.required label {
  font-weight: bold;
}

.form-field [type="checkbox"] {
  display: none;
}

.form-field [type="checkbox"] + label {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
}

.form-field [type="checkbox"] + label:before {
  content: "";
  background: #fff;
  border: 2px solid #656c81;
  border-radius: 1px;
  width: 12px;
  height: 12px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 1px;
  left: 0;
}

.form-field [type="checkbox"] + label:after {
  content: "✔";
  color: #90ac3b;
  opacity: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 4.75px;
  left: 2.75px;
  transform: scale(0);
}

.form-field [type="checkbox"] + label:hover:before {
  border-color: #303543;
}

.form-field [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.form-field input[type="radio"] {
  display: none;
}

.form-field input[type="radio"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: bold;
  position: relative;
}

.form-field input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 2px solid #656c81;
  border-radius: 18px;
  width: 13px;
  height: 13px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.form-field input[type="radio"] + label:after {
  content: "";
  color: #90ac3b;
  background: #90ac3b;
  border-radius: 350px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 8.25px;
  left: 8.7px;
}

.form-field input[type="radio"] + label:hover:before {
  border-color: #303543;
}

.form-field input[type="radio"]:checked + label:after {
  width: 9px;
  height: 9px;
  top: 3.75px;
  left: 4.2px;
}

table {
  background-color: #f1f1f6;
  width: 100%;
  margin: 4px 0;
  padding: 10px;
}

table td, table th {
  text-align: center;
  border-left: 1px solid #c2cbd4;
  border-right: 1px solid #c2cbd4;
}

table tbody tr td {
  padding-bottom: 8px;
}

table tbody tr:last-child td {
  border-bottom: 0;
  padding-bottom: 10px;
}

table tbody tr:first-child td {
  border-top: 0;
  padding-top: 20px;
}

table tbody tr td:first-child, table tbody tr th:first-child {
  border-left: 0;
}

table tbody tr td:last-child, table tbody tr th:last-child {
  border-right: 0;
}

table thead tr th, table thead tr td {
  border-top: 0;
  border-bottom: 1px solid #c2cbd4;
  padding-top: 10px;
  padding-bottom: 17px;
  font-weight: bold;
}

table thead tr th:first-child, table thead tr td:first-child {
  border-left: 0;
}

table thead tr th:last-child, table thead tr td:last-child {
  border-right: 0;
}

.cl-table tr td + td {
  text-align: left;
}

.ads table {
  background-color: #fff;
}

hr {
  background-color: #e2e2ea;
  border: 0;
}

.scrollbar-sample-wrapper .scrollbar-sample {
  color: #fff;
  background-color: #303543;
  width: 303px;
  height: 334px;
  margin: 20px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.scrollbar-sample-wrapper .scrollbar-sample .ps-scrollbar-y-rail {
  opacity: .6;
  cursor: pointer;
  background-color: #303543;
  width: 11px;
  margin-right: 7px;
}

.scrollbar-sample-wrapper .scrollbar-sample .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #656c81;
  width: 7px;
}

.link-with-icon {
  color: #303543;
  font-weight: regular;
  cursor: pointer;
  font-family: arial;
  font-size: 15px;
  display: inline-block;
  position: relative;
}

.link-with-icon .icon {
  display: inline-block;
}

.link-with-icon .icon.icon-letter {
  margin-top: -2px;
}

.link-with-icon:after, .link-with-icon:before {
  content: "";
  clear: both;
  display: table;
}

.link-with-icon.no-icon {
  padding-right: 0;
  line-height: 100%;
}

.link-with-icon.icon-left .icon {
  float: left;
  margin-right: 10px;
}

.link-with-icon.icon-right .icon {
  float: right;
  margin-left: 10px;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.gam-wrapper--padding {
  padding: 20px 0;
}

.advert {
  padding: 0 0 20px;
}

.advert a.type {
  display: block;
  overflow: auto;
}

.advert a.type:hover .title {
  text-decoration: underline;
  transition: color .2s;
}

.advert a.type span {
  float: left;
  width: 100%;
  padding: 2px 0 5px;
}

.advert a.type .title {
  float: left;
  width: 65%;
  margin-left: 45px;
  padding: 0;
}

.advert a.type .title:hover {
  text-decoration: underline;
  transition: color .2s;
}

.advert a.type img {
  float: left;
  width: 62px;
  height: 42px;
}

.advert:after, .advert:before {
  content: "";
  clear: both;
  display: table;
}

.sklik-ads {
  background-color: #fff;
  padding: 30px 30px 35px;
}

.sklik-ads .sklik-ad h2 {
  margin-bottom: 30px;
  overflow: auto;
}

.sklik-ads .sklik-ad h2 a {
  display: block;
}

.sklik-ads .sklik-ad h2 a span {
  float: left;
}

.sklik-ads .sklik-ad h2 a .title {
  clear: both;
}

.sklik-ads .sklik-ad h2 a .dash {
  clear: both;
  display: none;
}

.sklik-ads .sklik-ad h2 a .text {
  width: 95%;
}

.sklik-ads .sklik-ad h2 a:hover .title {
  text-decoration: underline;
  transition: all .2s;
}

.sklik-ads .sklik-ad h2 a:hover .text {
  color: #303543;
}

.sklik-ads .sklik-ad:last-of-type h2 {
  margin-bottom: 35px;
}

.sklik-ads:after, .sklik-ads:before {
  content: "";
  clear: both;
  display: table;
}

.sticky-wrapper {
  min-height: 600px;
  margin-bottom: 20px;
  position: relative;
}

.sticky-wrapper .fixed {
  position: fixed;
  top: 10px;
}

.idvert-wrapper {
  background-color: #fff;
  height: auto;
}

.idvert-article-content {
  margin-bottom: 45px;
}

.idvert-article-content a {
  color: #ec058d;
  text-underline-offset: 2px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.idvert-article-content a:hover {
  text-decoration: none;
}

.idvert-article-content img {
  margin-bottom: 5px;
}

.category-sticker {
  background-color: #ec058d;
}

.widesquare {
  margin: 20px 0;
}

.mimibazar-block.customizable {
  border-top: 0;
  margin: 0 0 20px;
}

.mimibazar-block.customizable h2 {
  color: #303543;
  font-family: arial;
  font-weight: 400;
}

.mimibazar-block.customizable .item:nth-child(4n+4) {
  margin-right: 0;
}

.mimibazar-block.customizable .item .image {
  background: #f1f1f6;
}

.mimibazar-block.customizable .item .title {
  color: #303543;
}

.mimibazar-block.customizable .item .price {
  color: #9199af;
}

.mimibazar-block.customizable .item .more {
  color: #00aeef;
  border-bottom-color: #00aeef;
}

.mimibazar-block.customizable .item .more:after, .mimibazar-block.customizable .item .more:before {
  background-color: #00aeef;
}

.mimibazar-block.customizable .item .more:hover {
  color: #9199af;
}

.mimibazar-block.customizable .item .more:hover:after, .mimibazar-block.customizable .item .more:hover:before {
  background-color: #9199af;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-top {
  margin-top: 15px;
}

.margin-bottom {
  margin-bottom: 15px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.padding-top {
  padding-top: 15px;
}

.padding-bottom {
  padding-bottom: 15px;
}

a.button-main, a.main-button {
  text-decoration: none;
}

.button-main, .main-button {
  color: #fff;
  background-color: #00aeef;
  border: 0;
  border-radius: 8px;
  padding: 11px 46px;
  font-size: 14px;
  font-weight: bold;
}

.button-main:hover, .main-button:hover {
  text-decoration: underline;
}

a.button-right-arrow {
  padding-right: 50px;
  position: relative;
}

a.button-right-arrow span {
  width: 12px;
  height: 18px;
  position: absolute;
  top: 11.4px;
  right: 22.5px;
}

input.button-right-arrow {
  background-image: url("/images/abicko/dist/svg/arrow-right-white.svg");
  background-position: right 22.5px top 11.4px;
  background-repeat: no-repeat;
  background-size: 12px 18px;
  padding-right: 50px;
  display: block;
}

.load-more.mobile {
  height: auto;
}

.load-more.mobile .main-button {
  text-align: center;
  text-transform: uppercase;
  background-color: #8400ff;
  width: 55%;
  margin: 1em auto;
  font-size: 22px;
  display: block;
}

.load-more.mobile.category-technics .main-button {
  background-color: #8400ff;
}

.load-more.mobile.category-nature .main-button {
  background-color: #8baa2e;
}

.load-more.mobile.category-culture .main-button {
  background-color: #00aeef;
}

@media screen and (max-width: 620px) {
  .load-more.mobile .main-button {
    width: 55%;
    margin: 1em auto;
    padding: .8em 1.2em;
    font-size: 1.1em;
  }
}

@media screen and (max-width: 450px) {
  .load-more.mobile .main-button {
    width: 60%;
    margin: 1em auto;
    padding: .8em 1.2em;
    font-size: .9em;
  }
}

.box404 {
  border-bottom: 1px solid #f1f1f6;
  margin-top: 3em;
  margin-bottom: .8em;
  padding-bottom: 2.1em;
}

.box404 .icon-404 {
  margin: 0 auto;
}

.box404 h3 {
  margin: 1.2em auto 1em;
  font-size: 24px;
}

.box404 a {
  margin-top: 2em;
}

.box404 .text404 {
  width: 75%;
  font-size: 17px;
  line-height: 1.4;
}

.box404.mobile {
  border-bottom: 1px solid #f1f1f6;
  margin: 2.2em 12px 2.6em;
  padding-bottom: 1.9em;
}

.box404.mobile img {
  width: 100%;
}

.box404.mobile h3 {
  margin: 1.6em auto 1.2em;
  font-size: 24px;
}

.box404.mobile .nav404 a {
  margin-top: 1.8em;
}

h2.mostread {
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 29px;
  line-height: 1.2;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.order-edition-success {
  margin-bottom: 23px;
}

.magazine-subscription h2 {
  font-size: 35px;
}

.magazine-subscription h3 {
  position: relative;
}

.magazine-subscription h3 .dataimg-gift {
  vertical-align: middle;
  width: 30px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 155px;
}

.magazine-subscription h3 .dataimg-gift svg path {
  fill: #e2e2ea;
}

.magazine-subscription .required-fields-info {
  margin-top: 6px;
  font-weight: bold;
}

.magazine-subscription .magazine-subscription-row {
  clear: both;
  border-bottom: 1px solid #f1f1f6;
  overflow: auto;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full {
  margin-bottom: 40px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full label {
  font-weight: normal;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full h3 {
  margin: 40px 0 20px;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full a {
  color: #ec058d;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #ec058d;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left, .magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  width: 50%;
  margin-bottom: 40px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .small-comment, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .small-comment {
  color: #a7b4c2;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 14px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .editions-count, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .editions-count {
  text-align: center;
  width: 55px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left h3, .magazine-subscription .magazine-subscription-row .magazine-subscription-right h3 {
  margin: 40px 0 20px;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left h3.no-margin-top, .magazine-subscription .magazine-subscription-row .magazine-subscription-right h3.no-margin-top {
  margin-top: 0;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left img, .magazine-subscription .magazine-subscription-row .magazine-subscription-right img {
  vertical-align: middle;
  margin-right: 20px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options {
  margin-top: 25px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row {
  margin-bottom: 8px;
  position: relative;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row label, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row label {
  font-weight: normal;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .calendar-field, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .calendar-field {
  margin-top: 7px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .sipo-number-box, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .sipo-number-box {
  display: none;
  position: absolute;
  bottom: -8.3px;
  right: 135px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field, .magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label {
  display: inline;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .sipo-number-box label.sipo-number-field-label {
  padding-right: 10px;
  font-weight: bold;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-select-row .sipo-number-box input.sipo-number-field {
  background-color: #fff;
  width: 215px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options .subscription-calendar-row, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options .subscription-calendar-row {
  margin-left: 31.5px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left .subscription-select-options label, .magazine-subscription .magazine-subscription-row .magazine-subscription-right .subscription-select-options label {
  margin-left: 1.5px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left.gift img, .magazine-subscription .magazine-subscription-row .magazine-subscription-right.gift img {
  width: 100%;
  max-width: 460px;
  height: auto;
  max-height: 140px;
  margin: 50px auto;
  display: block;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left {
  float: left;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  float: right;
}

.magazine-subscription .magazine-subscription-row.from {
  overflow: visible;
}

.magazine-subscription .magazine-subscription-row:after, .magazine-subscription .magazine-subscription-row:before {
  content: "";
  clear: both;
  display: table;
}

.magazine-subscription .first-mag-subscription-row {
  border-top: 1px solid #f1f1f6;
  margin-top: 38px;
}

.magazine-subscription .first-mag-subscription-row.magazine-subscription-row {
  height: 225px;
  overflow: visible;
}

.magazine-subscription .button-row {
  text-align: center;
  padding-top: 35px;
}

.magazine-subscription .button-row input {
  text-transform: uppercase;
}

.magazine-subscription .subscription-address-options .subscription-field {
  margin-bottom: 8px;
}

.magazine-subscription .subscription-address-options .subscription-field label {
  width: 110px;
  display: inline-block;
}

.magazine-subscription .subscription-address-options .subscription-field input {
  width: 215px;
  margin-left: 5px;
}

.magazine-subscription .subscription-address-options .address-numbers input {
  width: 82px;
}

.magazine-subscription .subscription-address-options .address-numbers .address-slash {
  vertical-align: middle;
  text-align: center;
  width: 37px;
  display: inline-block;
}

.magazine-subscription .subscription-address-options .address-numbers .margin-address-number {
  margin-left: 5px;
}

.head-wrapper {
  width: 100%;
}

.head-wrapper .head-homepage-row {
  color: #a7a7c2;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
}

.head-wrapper .head-homepage-row a {
  color: #a7a7c2;
  text-decoration: none;
}

.head-wrapper .head-homepage-row a:hover {
  text-decoration: underline;
}

.head-wrapper .head-homepage-row a.pink {
  color: #ec058d;
}

.head-wrapper .head-homepage-row .head-row-left {
  float: left;
  width: 350px;
  height: 115px;
  position: relative;
  left: -9px;
  overflow: visible;
}

.head-wrapper .head-homepage-row .head-row-left .last-article-image {
  float: left;
  width: 130px;
  height: 100%;
  overflow: hidden;
}

.head-wrapper .head-homepage-row .head-row-left .last-article-image .image-archive-wrapper {
  padding-top: 6px;
  padding-left: 8px;
  transform: rotate(-4deg);
}

.head-wrapper .head-homepage-row .head-row-left .last-article-image img {
  width: 119px;
  height: 155px;
}

.head-wrapper .head-homepage-row .head-row-left .dataimg-new-magazine {
  float: right;
  width: 220px;
  height: 90px;
  position: relative;
  bottom: 10px;
  left: 5px;
}

.head-wrapper .head-homepage-row .head-row-left p {
  position: absolute;
  top: 88px;
  left: 150px;
}

.head-wrapper .head-homepage-row .head-row-right {
  float: right;
  width: 235px;
  padding-top: 20px;
  overflow: visible;
}

.head-wrapper .head-homepage-row .head-row-right .facebook-button {
  float: left;
  width: 125px;
}

.head-wrapper .head-homepage-row .head-row-right .search-holder {
  margin-top: 50px;
}

.head-wrapper .head-homepage-row .head-row-right .search-form {
  float: right;
  background-color: #e2e2ea;
  width: 235px;
  padding-top: 4px;
  position: relative;
  bottom: 6.5px;
  overflow: auto;
}

.head-wrapper .head-homepage-row .head-row-right .search-form input[type="text"] {
  color: #a7a7c2;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  position: relative;
  bottom: 5px;
}

.head-wrapper .head-homepage-row .head-row-right .search-form .default-form {
  border-bottom: 1px solid #f1f1f6;
  width: 100%;
}

.head-wrapper .head-homepage-row .head-row-right .search-form .default-form .currency-input {
  background-color: #e2e2ea;
  border: 0;
  width: 208px;
  padding: 4.5px 10px 0 7px;
  display: inline-block;
}

.head-wrapper .head-homepage-row .head-row-right .search-form .default-form .main-button.button-search {
  background-color: #e2e2ea;
  border: 0;
  padding: 0;
}

.head-wrapper .head-homepage-row .head-row-right .search-form .default-form .main-button.button-search .dataimg-search-shadow {
  width: 17px;
  height: 17px;
  padding-top: 1px;
  padding-right: 1px;
  display: block;
}

.head-wrapper .head-homepage-row .head-row-right .search-form input:focus {
  outline: none;
}

.head-wrapper .head-homepage-row .head-row-right svg path {
  fill: #303543;
}

.head-wrapper .head-homepage-row .head-row-right .login-link {
  text-align: right;
  float: right;
  width: 100px;
}

.head-wrapper .head-homepage-row .head-row-right .login-link a span {
  position: relative;
  top: 2px;
  right: 33px;
}

.head-wrapper .head-homepage-row .head-row-right .login-link a .dataimg-login-shadow {
  width: 22px;
  height: 22px;
  display: inline-block;
  position: absolute;
  top: 35px;
  right: -7px;
}

.head-wrapper .logo-center {
  width: 188px;
  height: 94px;
  margin: 0 auto;
  display: block;
}

.head-wrapper .logo-center div {
  width: 100%;
  height: 100%;
}

.head-wrapper .head-menu ul {
  text-align: center;
  border-top: 1px solid #f1f1f6;
  margin: 21px 0 0;
  padding-top: 21px;
  padding-left: 0;
  list-style-type: none;
}

.head-wrapper .head-menu ul li {
  margin: 0 11px;
  font-family: OpenSans, sans-serif;
  font-size: 20px;
  display: inline;
}

.head-wrapper .head-menu ul li a {
  color: #3c454d;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #fff, inset 0 -4px rgba(0, 0, 0, 0);
}

.head-wrapper .head-menu ul li a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.head-wrapper .head-menu ul li a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #fff, inset 0 -4px #3c454d;
}

.head-wrapper .oldmenu {
  margin-top: 17px;
  margin-bottom: 30px;
}

.head-wrapper.unimenu .head-row-right .login-link {
  position: relative;
}

.head-wrapper.unimenu .head-row-right .login-link a .dataimg-login-shadow {
  top: 0;
  right: 0;
}

.head-wrapper.head-other.unimenu {
  width: 940px;
}

.head-wrapper.unimenu {
  padding-top: 10px;
}

.head-wrapper.unimenu .head-others-row {
  color: #a7a7c2;
}

.head-wrapper.unimenu .head-others-row .icons-box {
  text-align: right;
  margin-right: 12px;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  position: relative;
}

.head-wrapper.unimenu .head-others-row .icons-box a {
  color: #a7a7c2;
  -webkit-text-decoration: no-decoration;
  text-decoration: no-decoration;
  padding-right: 41px;
  position: relative;
}

.head-wrapper.unimenu .head-others-row .icons-box a .dataimg-logo-grey {
  width: 34px;
  height: 15px;
  margin-right: 0;
  display: inline-block;
  position: absolute;
  bottom: 3.2px;
  right: 0;
}

.head-wrapper.unimenu .head-others-row .icons-box a svg path {
  fill: #a7a7c2;
}

.head-wrapper.unimenu .head-others-row .icons-box span {
  margin-right: 140px;
  position: relative;
}

.head-wrapper.unimenu .head-others-row .icons-box .facebook-button {
  width: 112px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.head-wrapper.unimenu .menu-box {
  border-bottom: 1px solid #f1f1f6;
  height: 66px;
  margin-bottom: 50px;
  padding-bottom: 21px;
  position: relative;
}

.head-wrapper.unimenu .menu-box:after, .head-wrapper.unimenu .menu-box:before {
  content: "";
  clear: both;
  display: table;
}

.head-wrapper.unimenu .menu-box .left-logo {
  float: left;
  width: 98px;
  height: 49px;
  line-height: 49px;
  display: block;
}

.head-wrapper.unimenu .menu-box .left-logo .dataimg-logo {
  width: 100%;
  height: 100%;
}

.head-wrapper.unimenu .menu-box .head-icons {
  float: right;
  height: 40px;
  display: block;
  position: relative;
  top: 13px;
}

.head-wrapper.unimenu .menu-box .head-icons a:hover {
  text-decoration: underline;
}

.head-wrapper.unimenu .menu-box .head-icons .head-search-link, .head-wrapper.unimenu .menu-box .head-icons .head-login-link {
  text-align: center;
  width: 35px;
  height: 100%;
  display: inline-block;
}

.head-wrapper.unimenu .menu-box .head-icons .head-search-link div, .head-wrapper.unimenu .menu-box .head-icons .head-login-link div {
  margin: 0 auto;
}

.head-wrapper.unimenu .menu-box .head-icons .head-search-link div {
  width: 20px;
  height: 20px;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link {
  width: 55px;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  position: relative;
  top: 9px;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link div {
  width: 21px;
  height: 21px;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link span {
  color: #a7a7c2;
  position: absolute;
  bottom: 0;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link .header-login-caption {
  z-index: 1;
  bottom: -2px;
  left: -2px;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link .header-logout-caption {
  z-index: 1;
  bottom: -2px;
  left: 2px;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link .header-logout-caption:hover {
  text-decoration: underline;
}

.head-wrapper.unimenu .menu-box .head-icons .head-login-link svg path {
  fill: #303543;
}

.head-wrapper.oldmenu {
  padding-top: 40px;
}

.head-wrapper.oldmenu .head-others-row {
  color: #a7a7c2;
}

.head-wrapper.oldmenu .head-others-row .icons-box {
  text-align: right;
  margin-bottom: 25px;
  margin-right: 12px;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  position: relative;
}

.head-wrapper.oldmenu .head-others-row .icons-box a {
  color: #a7a7c2;
  -webkit-text-decoration: no-decoration;
  text-decoration: no-decoration;
  padding-right: 41px;
  position: relative;
}

.head-wrapper.oldmenu .head-others-row .icons-box a .dataimg-logo-grey {
  width: 34px;
  height: 15px;
  margin-right: 0;
  display: inline-block;
  position: absolute;
  bottom: 3.2px;
  right: 0;
}

.head-wrapper.oldmenu .head-others-row .icons-box a svg path {
  fill: #a7a7c2;
}

.head-wrapper.oldmenu .head-others-row .icons-box span {
  margin-right: 140px;
  position: relative;
}

.head-wrapper.oldmenu .head-others-row .icons-box .facebook-button {
  width: 112px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.head-wrapper.oldmenu .menu-box {
  border-bottom: 1px solid #f1f1f6;
  padding-bottom: 15px;
}

.head-wrapper.oldmenu .menu-box .left-logo {
  width: 98px;
  height: 49px;
  margin-right: 16px;
  display: inline-block;
}

.head-wrapper.oldmenu .menu-box .left-logo .dataimg-logo {
  width: 100%;
  height: 100%;
}

.head-wrapper.oldmenu .menu-box .head-others-menu {
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  bottom: 12.7px;
}

.head-wrapper.oldmenu .menu-box .head-others-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.head-wrapper.oldmenu .menu-box .head-others-menu ul li {
  color: #3c454d;
  margin: 0 8px;
  padding: 0;
  display: inline-block;
}

.head-wrapper.oldmenu .menu-box .head-others-menu ul li a {
  color: #3c454d;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #fff, inset 0 -4px rgba(0, 0, 0, 0);
}

.head-wrapper.oldmenu .menu-box .head-others-menu ul li a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.head-wrapper.oldmenu .menu-box .head-others-menu ul li a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #fff, inset 0 -4px #303543;
}

.head-wrapper.oldmenu .menu-box .head-icons {
  float: right;
  height: 40px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: 13px;
}

.head-wrapper.oldmenu .menu-box .head-icons a:hover {
  text-decoration: underline;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-search-link, .head-wrapper.oldmenu .menu-box .head-icons .head-login-link {
  text-align: center;
  width: 35px;
  height: 100%;
  display: inline-block;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-search-link div, .head-wrapper.oldmenu .menu-box .head-icons .head-login-link div {
  margin: 0 auto;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-search-link div {
  width: 20px;
  height: 20px;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link {
  margin-left: 18px;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  position: relative;
  top: 9px;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link:hover span {
  text-decoration: underline;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link div {
  width: 21px;
  height: 21px;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link span {
  color: #a7a7c2;
  position: absolute;
  bottom: 0;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link .header-login-caption {
  bottom: -2px;
  left: -2px;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link .header-logout-caption {
  bottom: -2px;
  left: 2px;
}

.head-wrapper.oldmenu .menu-box .head-icons .head-login-link svg path {
  fill: #303543;
}

.header-dark {
  z-index: 100;
  background-color: #303543;
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
}

.header-dark:after, .header-dark:before {
  content: "";
  clear: both;
  display: table;
}

.header-dark .header-container {
  width: 1050px;
  margin: auto;
}

.header-dark .header-container .logo {
  float: left;
  width: 101px;
  height: 50px;
  margin: 5px 15px 0 0;
}

.header-dark .header-container .gallery-close {
  color: #fff;
  float: right;
  margin: 20px 0;
  font-size: 13px;
  font-weight: bold;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 5px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 20px;
}

.header-dark .header-container .gallery-close:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 12px 20px;
  font-weight: bold;
}

.header-dark .header-container .titles-container .title {
  color: #a7a7c2;
  float: left;
  text-transform: uppercase;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title, .header-dark .header-container .titles-container .article-title-link {
  color: #fff;
  float: left;
  clear: both;
  margin: 0;
  font-size: 16px;
}

.header-dark.mobile, .header-dark.mobile .header-container {
  width: 100%;
}

.header-dark.mobile .header-container .gallery-close {
  margin: 22px 5px 20px 0;
}

.header-dark.mobile .header-container .gallery-close span {
  left: 10px;
}

.header-dark.mobile .header-container .titles-container {
  padding: 20px 12px 12px;
}

.header-dark.mobile.comics .titles-container .title {
  color: #fff;
  font-size: 17px;
}

.header-dark.mobile.comics .titles-container .article-title {
  color: #a7a7c2;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 620px) {
  body.bg-color-dark {
    padding-top: 0;
  }

  .header-dark {
    height: auto;
    padding: 0 10px;
    position: relative;
  }

  .header-dark .header-container {
    width: 100%;
    max-width: 620px;
  }

  .header-dark .header-container .titles-container:after, .header-dark .header-container .titles-container:before {
    content: "";
    clear: both;
    display: table;
  }
}

.breadcrumbs-wrapper {
  z-index: 10;
  position: relative;
}

.breadcrumbs-wrapper .twelve-col {
  min-height: 0;
}

.unimenu .breadcrumbs {
  position: absolute;
  top: 86px;
}

.breadcrumbs {
  margin: 10px 15px;
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  font-size: 12px;
  list-style-type: none;
  display: inline-block;
}

.breadcrumbs ol li a {
  color: #a7a7c2;
  text-decoration: none;
}

.breadcrumbs ol li:last-child span {
  font-weight: bold;
}

.breadcrumbs ol .dataimg-arrow-right {
  vertical-align: middle;
  width: 7px;
  height: 11px;
  margin: 0 8px;
  position: relative;
  top: 1px;
}

.breadcrumbs ol .dataimg-arrow-right svg {
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 1px;
}

.breadcrumbs ol .dataimg-arrow-right svg path {
  fill: #a7a7c2;
}

.breadcrumbs ol li:hover a {
  text-decoration: underline;
}

.breadcrumbs ol li:last-of-type a {
  color: #303543;
  font-weight: bold;
}

.breadcrumbs ol .arrow-icon {
  display: inline-block;
}

.color-black .list-article, .color-black .article-mini-listing, .color-black {
  background-color: #303543;
}

.color-black .list-article, .color-black .list-article a, .color-green .list-article, .color-green .list-article a, .color-blue .list-article, .color-blue .list-article a, .color-orange .list-article, .color-orange .list-article a, .color-purple .list-article, .color-purple .list-article a {
  color: #fff;
}

.list-article a:hover span {
  color: #656c81;
}

.list-article-big a span h2 {
  color: #fff;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #fff, inset 0 -4px rgba(0, 0, 0, 0);
}

.list-article-big a span h2::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-article-big a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #fff, inset 0 -4px #656c81;
}

.list-article-mini a span h2 {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -3px rgba(0, 0, 0, 0);
}

.list-article-mini a span h2::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-article-mini a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -3px #656c81;
}

.color-black .list-article a:hover span {
  color: #c2c7d7;
}

.color-black .list-article-big a span h2 {
  text-shadow: 0 -2px #303543, 0 -1px #303543, 0 0 #303543, 2px -2px #303543, 2px -1px #303543, 2px 0 #303543, -2px -2px #303543, -2px -1px #303543, -2px 0 #303543, 1px -2px #303543, 1px -1px #303543, 1px 0 #303543, -1px -2px #303543, -1px -1px #303543, -1px 0 #303543, 0 -2px #303543, 0 -1px #303543, 0 0 #303543;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #303543, inset 0 -4px rgba(0, 0, 0, 0);
}

.color-black .list-article-big a span h2::selection {
  color: #303543;
  text-shadow: none;
  background: #515971;
}

.color-black .list-article-big a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #303543, inset 0 -4px #c2c7d7;
}

.color-black .list-article-mini a span h2 {
  text-shadow: 0 -2px #303543, 0 -1px #303543, 0 0 #303543, 2px -2px #303543, 2px -1px #303543, 2px 0 #303543, -2px -2px #303543, -2px -1px #303543, -2px 0 #303543, 1px -2px #303543, 1px -1px #303543, 1px 0 #303543, -1px -2px #303543, -1px -1px #303543, -1px 0 #303543, 0 -2px #303543, 0 -1px #303543, 0 0 #303543;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #303543, inset 0 -3px rgba(0, 0, 0, 0);
}

.color-black .list-article-mini a span h2::selection {
  color: #303543;
  text-shadow: none;
  background: #515971;
}

.color-black .list-article-mini a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #303543, inset 0 -3px #c2c7d7;
}

.color-green .list-article, .color-green .article-mini-listing, .color-green {
  background-color: #8baa2e;
}

.color-green .list-article a:hover span {
  color: #dceab1;
}

.color-green .list-article-big a span h2 {
  color: #fff;
  text-shadow: 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e, 2px -2px #8baa2e, 2px -1px #8baa2e, 2px 0 #8baa2e, -2px -2px #8baa2e, -2px -1px #8baa2e, -2px 0 #8baa2e, 1px -2px #8baa2e, 1px -1px #8baa2e, 1px 0 #8baa2e, -1px -2px #8baa2e, -1px -1px #8baa2e, -1px 0 #8baa2e, 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #8baa2e, inset 0 -4px rgba(0, 0, 0, 0);
}

.color-green .list-article-big a span h2::selection {
  color: #8baa2e;
  text-shadow: none;
  background: #abcd46;
}

.color-green .list-article-big a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #8baa2e, inset 0 -4px #dceab1;
}

.color-green .list-article-mini a span h2 {
  color: #fff;
  text-shadow: 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e, 2px -2px #8baa2e, 2px -1px #8baa2e, 2px 0 #8baa2e, -2px -2px #8baa2e, -2px -1px #8baa2e, -2px 0 #8baa2e, 1px -2px #8baa2e, 1px -1px #8baa2e, 1px 0 #8baa2e, -1px -2px #8baa2e, -1px -1px #8baa2e, -1px 0 #8baa2e, 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #8baa2e, inset 0 -3px rgba(0, 0, 0, 0);
}

.color-green .list-article-mini a span h2::selection {
  color: #8baa2e;
  text-shadow: none;
  background: #abcd46;
}

.color-green .list-article-mini a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #8baa2e, inset 0 -3px #dceab1;
}

.color-blue .list-article, .color-blue .article-mini-listing, .color-blue {
  background-color: #00aeef;
}

.color-blue .list-article a:hover span {
  color: #b4e6f9;
}

.color-blue .list-article-big a span h2 {
  color: #fff;
  text-shadow: 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef, 2px -2px #00aeef, 2px -1px #00aeef, 2px 0 #00aeef, -2px -2px #00aeef, -2px -1px #00aeef, -2px 0 #00aeef, 1px -2px #00aeef, 1px -1px #00aeef, 1px 0 #00aeef, -1px -2px #00aeef, -1px -1px #00aeef, -1px 0 #00aeef, 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #00aeef, inset 0 -4px rgba(0, 0, 0, 0);
}

.color-blue .list-article-big a span h2::selection {
  color: #00aeef;
  text-shadow: none;
  background: #26c4ff;
}

.color-blue .list-article-big a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #00aeef, inset 0 -4px #b4e6f9;
}

.color-blue .list-article-mini a span h2 {
  color: #fff;
  text-shadow: 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef, 2px -2px #00aeef, 2px -1px #00aeef, 2px 0 #00aeef, -2px -2px #00aeef, -2px -1px #00aeef, -2px 0 #00aeef, 1px -2px #00aeef, 1px -1px #00aeef, 1px 0 #00aeef, -1px -2px #00aeef, -1px -1px #00aeef, -1px 0 #00aeef, 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #00aeef, inset 0 -3px rgba(0, 0, 0, 0);
}

.color-blue .list-article-mini a span h2::selection {
  color: #00aeef;
  text-shadow: none;
  background: #26c4ff;
}

.color-blue .list-article-mini a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #00aeef, inset 0 -3px #b4e6f9;
}

.color-orange .list-article, .color-orange .article-mini-listing, .color-orange {
  background-color: #ff9c00;
}

.color-orange .list-article a:hover span {
  color: #f8e6ca;
}

.color-orange .list-article a:hover span h2 {
  color: #fff;
  text-shadow: 0 -2px #ff9c00, 0 -1px #ff9c00, 0 0 #ff9c00, 2px -2px #ff9c00, 2px -1px #ff9c00, 2px 0 #ff9c00, -2px -2px #ff9c00, -2px -1px #ff9c00, -2px 0 #ff9c00, 1px -2px #ff9c00, 1px -1px #ff9c00, 1px 0 #ff9c00, -1px -2px #ff9c00, -1px -1px #ff9c00, -1px 0 #ff9c00, 0 -2px #ff9c00, 0 -1px #ff9c00, 0 0 #ff9c00;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -4px #ff9c00, inset 0 -6px rgba(0, 0, 0, 0);
}

.color-orange .list-article a:hover span h2::selection {
  color: #ff9c00;
  text-shadow: none;
  background: #ffb033;
}

.color-orange .list-article a:hover span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -4px #ff9c00, inset 0 -6px #f8e6ca;
}

.color-purple .list-article, .color-purple .article-mini-listing, .color-purple {
  background-color: #8400ff;
}

.color-purple .list-article a:hover span {
  color: #e0c5f8;
}

.color-purple .list-article-big a span h2 {
  color: #fff;
  text-shadow: 0 -2px #8400ff, 0 -1px #8400ff, 0 0 #8400ff, 2px -2px #8400ff, 2px -1px #8400ff, 2px 0 #8400ff, -2px -2px #8400ff, -2px -1px #8400ff, -2px 0 #8400ff, 1px -2px #8400ff, 1px -1px #8400ff, 1px 0 #8400ff, -1px -2px #8400ff, -1px -1px #8400ff, -1px 0 #8400ff, 0 -2px #8400ff, 0 -1px #8400ff, 0 0 #8400ff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #8400ff, inset 0 -4px rgba(0, 0, 0, 0);
}

.color-purple .list-article-big a span h2::selection {
  color: #8400ff;
  text-shadow: none;
  background: #9d33ff;
}

.color-purple .list-article-big a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #8400ff, inset 0 -4px #e0c5f8;
}

.color-purple .list-article-mini a span h2 {
  color: #fff;
  text-shadow: 0 -2px #8400ff, 0 -1px #8400ff, 0 0 #8400ff, 2px -2px #8400ff, 2px -1px #8400ff, 2px 0 #8400ff, -2px -2px #8400ff, -2px -1px #8400ff, -2px 0 #8400ff, 1px -2px #8400ff, 1px -1px #8400ff, 1px 0 #8400ff, -1px -2px #8400ff, -1px -1px #8400ff, -1px 0 #8400ff, 0 -2px #8400ff, 0 -1px #8400ff, 0 0 #8400ff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #8400ff, inset 0 -3px rgba(0, 0, 0, 0);
}

.color-purple .list-article-mini a span h2::selection {
  color: #8400ff;
  text-shadow: none;
  background: #9d33ff;
}

.color-purple .list-article-mini a span h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #8400ff, inset 0 -3px #e0c5f8;
}

.edition-number {
  text-align: center;
  border: 1px solid #cbd5df;
  border-bottom-right-radius: 20px;
  width: 160px;
  padding: 5px;
}

.edition-number img {
  margin: 0;
}

.edition-number a:hover span {
  text-decoration: underline;
}

.edition-number a {
  color: #303543;
  text-decoration: none;
}

.edition-number a span {
  margin: 13px 0 8px;
  font-size: 17px;
  font-weight: bold;
  display: block;
}

.list-komiks .edition-number {
  float: left;
  background-color: #e1e2e7;
  width: 168px;
  height: 250px;
  margin: 16px;
  padding: 0;
  position: relative;
}

.list-komiks .edition-number a {
  text-align: center;
}

.list-komiks .edition-number a img {
  border-bottom: 1px solid #a7a7c2;
  width: 162px;
  height: 182px;
  display: inline-block;
}

.list-komiks .edition-number a span {
  text-align: center;
  width: 152px;
  height: 65px;
  margin-top: 0;
  padding-top: 18px;
  font-size: 17px;
  display: inline-block;
}

@media screen and (max-width: 620px) {
  .list-komiks {
    text-align: center;
    margin: 10px auto;
  }

  .list-komiks .edition-number {
    width: 31%;
    height: auto;
    margin: 1.5% 1%;
  }

  .list-komiks .edition-number a img {
    float: left;
    width: 100%;
    height: auto;
    display: block;
  }

  .list-komiks .edition-number a img:after, .list-komiks .edition-number a img:before {
    content: "";
    clear: both;
    display: table;
  }

  .list-komiks .edition-number a span {
    float: left;
    text-align: center;
    width: 100%;
    height: 68px;
    padding-top: 10px;
    font-size: 17px;
    line-height: 1.4;
    display: block;
  }

  .list-komiks .edition-number a span:after, .list-komiks .edition-number a span:before, .list-komiks .edition-number a:after, .list-komiks .edition-number a:before, .list-komiks .edition-number:after, .list-komiks .edition-number:before, .list-komiks:after, .list-komiks:before {
    content: "";
    clear: both;
    display: table;
  }
}

.carousel-comics-parts-nav .edition-number {
  width: 98%;
}

@media screen and (max-width: 450px) {
  .list-komiks .edition-number {
    width: 46%;
    margin: 1%;
    padding: 0;
  }

  .list-komiks .edition-number a img {
    float: left;
    width: 100%;
    height: auto;
    display: block;
  }

  .list-komiks .edition-number a img:after, .list-komiks .edition-number a img:before {
    content: "";
    clear: both;
    display: table;
  }

  .list-komiks .edition-number a span {
    float: left;
    text-align: center;
    width: 100%;
    height: 68px;
    padding-top: 12px;
    font-size: 14px;
    line-height: 1.5;
    display: block;
  }

  .list-komiks .edition-number a span:after, .list-komiks .edition-number a span:before, .list-komiks .edition-number:after, .list-komiks .edition-number:before {
    content: "";
    clear: both;
    display: table;
  }
}

.sharer {
  position: relative;
}

.sharer .sharer-background {
  z-index: 800;
  background-color: #f1f1f6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sharer .sharer-buttons {
  z-index: 900;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sharer .sharer-button {
  z-index: 850;
  transition: opacity .3s ease-in;
}

.sharer .sharer-button:hover {
  transition: opacity .3s ease-out;
}

.sharer .sharer-button:hover .hover-icon {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.sharer a svg path {
  fill: #a7a7c2;
}

.sharer a:hover svg path {
  fill: #757591;
}

.sharer-vertical-2, .sharer-vertical {
  width: 38px;
  height: 85px;
}

.sharer-vertical-2 .sharer-buttons, .sharer-vertical .sharer-buttons {
  padding: 7px 10px 5px;
}

.sharer-vertical-2 .sharer-facebook, .sharer-vertical-2 .sharer-twitter, .sharer-vertical .sharer-facebook, .sharer-vertical .sharer-twitter {
  padding-bottom: 25px;
  position: relative;
}

.sharer-vertical-2 .sharer-comments, .sharer-vertical .sharer-comments {
  position: relative;
  left: -1.5px;
}

.sharer-vertical-2 .hover-icon, .sharer-vertical .hover-icon {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sharer-vertical-2 a, .sharer-vertical a {
  width: 20px;
  height: 20px;
  display: block;
}

.sharer-vertical-2 {
  height: 58px;
}

.sharer-horizontal {
  text-align: center;
  width: 84px;
  height: 38px;
}

.sharer-horizontal .sharer-buttons {
  padding: 9px 6px 0;
}

.sharer-horizontal .sharer-facebook {
  float: left;
  position: relative;
}

.sharer-horizontal .sharer-twitter {
  position: relative;
}

.sharer-horizontal .sharer-comments {
  float: right;
  position: relative;
}

.sharer-horizontal .hover-icon {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sharer-horizontal a {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.color-black .sharer .sharer-background {
  opacity: .2;
}

.color-black .sharer .sharer-button {
  opacity: .4;
}

.color-black .sharer .sharer-button:hover {
  opacity: .6;
}

.color-green .sharer .sharer-background {
  opacity: .2;
}

.color-green .sharer .sharer-button {
  opacity: .35;
}

.color-green .sharer .sharer-button:hover {
  opacity: .6;
}

.color-blue .sharer .sharer-background, .color-blue .sharer .sharer-button {
  opacity: .3;
}

.color-blue .sharer .sharer-button:hover {
  opacity: .6;
}

.color-orange .sharer .sharer-background, .color-purple .sharer .sharer-background {
  opacity: .3;
}

.color-purple .sharer .sharer-button {
  opacity: .28;
}

.color-purple .sharer .sharer-button:hover {
  opacity: .6;
}

.paging {
  text-align: center;
  clear: both;
  border-top: 1px solid #e2e2ea;
  margin-bottom: 38px;
  padding-top: 25px;
}

.paging .prev {
  float: left;
  width: 16px;
  display: block;
}

.paging .next {
  float: right;
  width: 16px;
  display: block;
}

.paging .page-links a {
  color: #a7a7c2;
  padding: 7px;
  font-size: 18px;
  display: inline-block;
}

.paging .page-links .aktpage {
  color: #fff;
  background-color: #00aeef;
}

.paging .prev a, .paging .next a {
  width: 100%;
  display: block;
  position: relative;
  top: 5px;
}

.paging svg path {
  fill: #a7a7c2;
}

.latest-news .paging {
  border-top: 0;
}

.rating {
  text-align: center;
}

.rating .rate-star {
  width: 28px;
  margin: 0 auto;
  display: inline-block;
}

.rating .rate-star svg polyline {
  fill: #e2e2ea;
}

.rating .rate-star .rate-star-left {
  float: left;
  position: relative;
  left: .95px;
}

.rating .rate-star .rate-star-left:hover svg polyline {
  fill: #ffb600;
}

.rating .rate-star .rate-star-right {
  float: right;
  position: relative;
  left: -.95px;
}

.rating .rate-star .rate-star-right:hover svg polyline, .rating .rate-star .hover svg polyline {
  fill: #ffb600;
}

.rating .rate-caption {
  padding-bottom: 5px;
  font-size: 12px;
}

.rating .rate-star .rate-star-left, .rating .rate-star .rate-star-right {
  cursor: pointer;
  width: 50%;
  display: inline-block;
}

.new-joke .form-panes {
  margin-top: 32px;
}

.new-joke .left-pane {
  float: left;
  width: 50%;
}

.new-joke .right-pane {
  float: right;
  width: 50%;
}

.new-joke .left-pane input, .new-joke .right-pane input {
  width: 100%;
}

.keywords {
  color: #9199af;
  border-top: 1px solid #f1f1f6;
  border-bottom: 1px solid #f1f1f6;
}

.keywords:after, .keywords:before {
  content: "";
  clear: both;
  display: table;
}

.keywords a {
  color: #9199af;
}

.keywords a:hover {
  text-decoration: underline;
}

.keywords div {
  text-transform: uppercase;
  margin: 11px 0 9px;
  font-size: 13px;
  font-weight: normal;
}

.keywords span {
  margin-bottom: 9px;
  display: block;
}

.mobile .keywords {
  border-top: 0;
  width: 100%;
  padding-left: 12px;
  line-height: 29px;
}

.mobile .keywords div, .mobile .keywords span a {
  font-family: opensans, arial, sans-serif;
  font-size: 17px;
}

.mobile .keywords:after, .mobile .keywords:before {
  content: "";
  clear: both;
  display: table;
}

.discussion-under-article .discussion-post:first-of-type {
  border-top: 0;
}

.discussion-post {
  border-top: 1px solid #f1f1f6;
  padding-top: 22px;
  padding-bottom: 8px;
}

.discussion-post .discussion-left-pane {
  vertical-align: top;
  clear: right;
  width: 147px;
  padding-top: 5px;
  display: inline-block;
}

.discussion-post .author {
  color: #303543;
  padding-bottom: 9px;
  font-size: 14px;
  font-weight: bold;
}

.discussion-post .datetime {
  color: #9199af;
  font-size: 14px;
}

.discussion-post .discussion-right-pane {
  color: #303543;
  width: calc(100% - 160px);
  display: inline-block;
}

.discussion-post .discussion-right-pane .post-content {
  font-size: 14px;
  line-height: 24px;
}

.discussion-post .discussion-right-pane .post-content div {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.discussion-post .links {
  clear: both;
  padding-top: 7px;
  overflow: auto;
}

.discussion-post .links a {
  color: #303543;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}

.discussion-post .links a:hover {
  text-decoration: underline;
}

.discussion-post .links .report-link {
  float: left;
  text-align: left;
}

.discussion-post .links .react-link {
  float: right;
  text-align: right;
}

.discussion-post-second-response {
  margin-left: 55px;
}

.discussion-post-first-response, .discussion-post-second-response {
  padding-left: 55px;
}

.new-discussion-post .new-discussion-post-content {
  clear: both;
  overflow: auto;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left {
  float: left;
  width: calc(100% - 118px);
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left div {
  float: right;
  color: #9199af;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left .report-answers {
  float: left;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left .report-answers label {
  padding-top: .5px;
  padding-left: 25px;
  font-weight: normal;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left .new-discussion-field {
  background-color: #f0f2f4;
  border: 1px solid #f0f2f4;
  width: 100%;
  height: 255px;
  margin-top: 10px;
  margin-bottom: 11px;
  padding: 5px;
  display: block;
  overflow-y: scroll;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left .new-discussion-field img {
  width: 19px;
  height: 19px;
  margin: 0 3px;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-left .button-main {
  float: right;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-right {
  float: right;
  width: 118px;
  padding-top: 25px;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-right .new-discussion-smiles-row {
  clear: both;
  overflow: auto;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-right .new-discussion-smiles-row .new-discussion-smile-item-left div {
  position: relative;
  left: 10px;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-right .new-discussion-smiles-row .new-discussion-smile-item {
  text-align: center;
  width: 48.1199%;
  display: inline-block;
}

.new-discussion-post .new-discussion-post-content .new-discussion-content-right .new-discussion-smiles-row .new-discussion-smile-item div {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
}

.new-edition {
  text-align: center;
  background-image: url("/images/abicko/dist/non-sprite/new-edition-background.png");
  background-position: top;
  background-repeat: no-repeat;
  font-family: Gargle, Arial, sans-serif;
  font-size: 32px;
}

.new-edition:hover .new-abc-cover {
  transition: bottom .1s ease-out;
  bottom: 3px;
}

.new-edition .new-edition-head {
  text-align: left;
  color: #ff2400;
  width: 160px;
  margin: 0 auto;
  padding-top: 4px;
  padding-bottom: 15px;
  position: relative;
}

.new-edition .new-edition-head span {
  display: block;
  position: relative;
  left: -10px;
  transform: rotate(-3deg);
}

.new-edition .new-edition-head div {
  background-size: 58px 51px;
  width: 58px;
  height: 51px;
  position: absolute;
  top: 23px;
  right: -8px;
}

.new-edition .new-edition-head div hr {
  display: none;
}

.new-edition .new-abc-cover {
  transition: bottom .1s ease-in;
  position: relative;
  bottom: 0;
}

.list-article-mini {
  width: 300px;
  height: 267px;
  position: relative;
}

.list-article-mini .dataimg-play {
  opacity: .75;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 53.5px;
  left: calc(50% - 29px);
}

.list-article-mini a:hover {
  color: #656c81;
}

.list-article-mini .list-article-mini-link {
  color: #303543;
  font-size: 18.5px;
  font-weight: bold;
  text-decoration: none;
}

.list-article-mini .list-article-mini-link .list-article-image {
  width: 100%;
  position: relative;
}

.list-article-mini .list-article-mini-link .list-article-image img {
  width: 100%;
  height: 165px;
  display: block;
}

.list-article-mini .list-article-mini-link .list-article-mini-title {
  text-align: left;
  width: 280px;
  padding: 16px 30px 0 15px;
  display: block;
}

.list-article-mini .sharer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-article-mini .category-sticker {
  position: absolute;
  top: 0;
  left: 0;
}

.list-article-mini .list-article-mini-title-holder {
  position: relative;
}

.list-article-mini .list-article-mini-title-holder .video-data-info {
  background-color: #ec058d;
  width: 45px;
  height: 101.5%;
  position: absolute;
  top: 0;
  right: 0;
}

.list-article-mini .list-article-mini-title-holder .list-article-mini-title h2 {
  font-family: OpenSans, Arial, sans-serif;
  font-size: inherit;
  margin: 0;
  padding: 0;
  display: inline;
}

.article-mini-listing {
  text-align: center;
  clear: both;
  padding-top: 30px;
  padding-bottom: 20px;
  overflow: auto;
}

.article-mini-listing > div {
  display: inline-block;
}

.article-mini-listing > div:first-child {
  float: left;
}

.article-mini-listing > div:last-child {
  float: right;
}

.list-article-medium {
  height: 165px;
  margin: 45px 0;
  position: relative;
}

.list-article-medium-picture {
  width: 300px;
}

.list-article-medium .category-sticker {
  position: absolute;
  top: 0;
  left: 0;
}

.list-article-medium .category-sticker.pravidla-souteze {
  background-color: #385898;
}

.list-article-medium a {
  text-decoration: none;
}

.list-article-medium a .dataimg-play {
  opacity: .75;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 53.5px;
  left: calc(50% - 29px);
}

.list-article-medium .list-article-medium-picture {
  float: left;
  line-height: 0;
  position: relative;
}

.list-article-medium .list-article-medium-picture img {
  z-index: 100;
  width: 300px;
  height: 165px;
}

.list-article-medium .list-article-medium-picture .no-img {
  background-color: #f1f1f6;
  background-position: center;
  background-size: 50%;
  width: 300px;
  height: 165px;
}

.list-article-medium .list-article-medium-picture .no-img svg {
  color: #a7a7c2;
}

.list-article-medium .list-article-medium-content {
  float: right;
  width: calc(100% - 300px);
  height: 100%;
  position: relative;
}

.list-article-medium .list-article-medium-content h2 {
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-family: OpenSans, Arial, sans-serif;
  display: inline;
}

.list-article-medium .list-article-medium-content .list-article-medium-title {
  color: #303543;
  padding-left: 20px;
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
}

.list-article-medium .sharer {
  position: absolute;
  bottom: 0;
  left: 320px;
}

.list-article-medium-envelope:hover .list-article-medium-title {
  color: #656c81;
}

.list-article-medium-envelope .list-article-medium-title h2 {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -3px rgba(0, 0, 0, 0);
}

.list-article-medium-envelope .list-article-medium-title h2::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-article-medium-envelope .list-article-medium-title h2:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -3px #656c81;
}

.list-article-big {
  width: 100%;
  position: relative;
}

.list-article-big .list-article-big-image {
  height: 335px;
  position: relative;
}

.list-article-big .list-article-big-image img {
  width: 100%;
  height: 100%;
}

.list-article-big .list-article-big-caption {
  font-family: OpenSans, Arial, sans-serif;
  font-weight: bold;
  position: relative;
}

.list-article-big .list-article-big-caption div {
  min-height: 110px;
  margin-right: 38px;
  padding: 15px 15px 10px;
  font-size: 32px;
  display: block;
}

.list-article-big .list-article-big-caption div h2 {
  color: inherit;
  margin: 0;
  padding: 0;
  font-size: 36px;
  display: inline;
}

.list-article-big .dataimg-play {
  opacity: .75;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 123.5px;
  left: calc(50% - 50px);
}

.list-article-big .category-sticker {
  position: absolute;
  top: 0;
  left: 0;
}

.list-article-big .video-data-info {
  background-color: #ec058d;
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.list-article-big .sharer {
  height: 84px;
  position: absolute;
  top: 355px;
  right: 0;
}

.recent-articles .list-article-big {
  margin: 16px auto;
}

.recent-articles .list-article-big-caption div {
  margin-right: 38px;
  padding: 32px 15px;
}

.recent-articles .sharer {
  bottom: 0;
}

.article-align-left .list-article-big-caption div {
  text-align: left;
}

.article-align-center .list-article-big-caption div, .homepage-top .list-article-big-caption div {
  text-align: center;
  margin-right: 0;
  padding: 15px 43px 10px;
}

.article-align-right .list-article-big-caption div {
  text-align: right;
}

.list-gallery {
  padding: 10px 0;
}

.list-gallery .gallery-item {
  margin-right: .5px;
  display: inline-block;
}

.category-sticker {
  text-transform: uppercase;
  color: #fff;
  border-bottom-right-radius: 18px;
  padding: 9px 0;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  display: inline-block;
}

.category-sticker .category-sticker-icon {
  width: 21px;
  height: 21px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1.5px;
}

.category-sticker p {
  vertical-align: top;
  height: 22px;
  margin-right: 12px;
  padding: 2px 0 0;
  line-height: 22px;
  display: inline-block;
}

.category-sticker:hover p {
  text-decoration: underline;
}

.category-sticker:hover .list-article-medium-category-icon {
  text-decoration: none;
}

.category-sticker .list-article-medium-category-icon {
  padding-left: 2px;
  padding-right: 3.5px;
  text-decoration: none;
  position: relative;
}

.category-sticker .list-article-medium-category-icon .dataimg-video svg {
  width: 19px;
  height: 19px;
}

.category-sticker .list-article-medium-category-icon .dataimg-vystrihovanky svg {
  margin-top: 2px;
}

.category-sticker .list-article-medium-category-icon svg {
  width: 18px;
  margin: 0 1px;
  position: absolute;
  top: 0;
  left: 3px;
}

.category-sticker .list-article-medium-category-icon svg path, .category-sticker .list-article-medium-category-icon svg circle, .category-sticker .list-article-medium-category-icon svg polygon {
  fill: #fff;
}

.category-science .category-sticker, .category-joke .category-sticker {
  background-color: #ff9c00;
}

.category-technics .category-sticker {
  background-color: #8400ff;
}

.category-nature .category-sticker {
  background-color: #8baa2e;
}

.category-culture .category-sticker {
  background-color: #00aeef;
}

.category-comics .category-sticker, .category-video .category-sticker, .category-mourrison .category-sticker {
  background-color: #ec058d;
}

.category-sticker-full-width {
  width: 100%;
}

.category-sticker-title {
  height: 54px;
  padding: 0;
  font-size: 25px;
  line-height: 54px;
}

.category-sticker-title p {
  height: 52px;
  margin-left: 42px;
  line-height: 52px;
  display: inline-block;
}

.category-sticker-title .list-article-medium-category-icon {
  width: 32px;
  height: 52px;
  line-height: 52px;
  position: relative;
}

.category-sticker-title .list-article-medium-category-icon svg {
  width: 32px;
  height: auto;
  line-height: 52px;
  position: absolute;
  top: 0;
  left: 3px;
}

.title-section {
  text-transform: uppercase;
}

.title-section:after, .title-section:before {
  content: "";
  clear: both;
  display: table;
}

.title-section .title-section-category-icon span {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.title-section .title-section-category-icon span svg {
  height: auto;
}

.title-section .title-section-category-icon .dataimg-video {
  width: 19px;
  height: 25px;
}

.title-section .title-section-category-icon .dataimg-video svg, .title-section .title-section-category-icon .dataimg-vystrihovanky {
  height: 24px;
}

.title-section .title-section-category-icon .dataimg-vystrihovanky svg {
  height: 23px;
}

.title-section .title-section-category-icon .dataimg-souteze {
  height: 27px;
}

.title-section .title-section-category-icon .dataimg-souteze svg {
  height: 26px;
}

.title-section .title-section-category-icon .dataimg-komiksy {
  height: 27px;
}

.title-section .title-section-category-icon .dataimg-komiksy svg {
  height: 26px;
}

.title-section .title-section-category-icon .dataimg-mourrison {
  height: 27px;
}

.title-section .title-section-category-icon .dataimg-mourrison svg {
  height: 26px;
}

.title-section a:hover h1 {
  text-decoration: underline;
}

.title-section h1 {
  margin: 0;
  padding: 0 0 0 1px;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 23px;
  font-weight: normal;
  display: inline;
  position: relative;
  bottom: 3.5px;
}

.category-science .title-section h1 {
  color: #ff9c00;
}

.category-science .title-section path, .category-science .title-section circle {
  fill: #ff9c00;
}

.category-technics .title-section h1 {
  color: #8400ff;
}

.category-technics .title-section path, .category-technics .title-section circle {
  fill: #8400ff;
}

.category-nature .title-section h1 {
  color: #8baa2e;
}

.category-nature .title-section path, .category-nature .title-section circle {
  fill: #8baa2e;
}

.category-culture .title-section h1 {
  color: #00aeef;
}

.category-culture .title-section path, .category-culture .title-section circle {
  fill: #00aeef;
}

.category-mourrison .title-section h1 {
  color: #ec058d;
}

.category-mourrison .title-section path, .category-mourrison .title-section circle {
  fill: #ec058d;
}

.background .title-section {
  border-bottom-right-radius: 18px;
  width: 100%;
  height: 48px;
  padding-left: 8px;
  font-size: 25px;
  font-weight: 700;
  line-height: 48px;
  display: block;
}

.background .title-section a h1 {
  color: #fff;
  vertical-align: middle;
  height: 48px;
  font-size: 23px;
  font-weight: 700;
  bottom: 0;
}

.background .title-section a h1.no-icon {
  margin-left: 24px;
}

.background .title-section a .title-section-category-icon span {
  vertical-align: middle;
}

.background .title-section a .title-section-category-icon svg {
  width: auto;
}

.background .title-section a .title-section-category-icon svg path, .background .title-section a .title-section-category-icon svg circle, .background .title-section a .title-section-category-icon svg polygon {
  fill: #fff;
}

.background.category-science .title-section, .background.category-joke .title-section {
  background-color: #ff9c00;
}

.background.category-technics .title-section {
  background-color: #8400ff;
}

.background.category-nature .title-section {
  background-color: #8baa2e;
}

.background.category-authors .title-section, .background.category-culture .title-section {
  background-color: #00aeef;
}

.background.category-video .title-section, .background.category-other .title-section {
  background-color: #ec058d;
  bottom: 10.5px;
}

.background.category-mourrison .title-section {
  background-color: #ec058d;
}

.background.category-comics .title-section {
  background-color: #ec058d;
  margin-top: 0;
}

h1.category-title {
  margin-bottom: 1em;
  font-size: 29px;
  line-height: 34.8px;
}

h1.category-title.category-technics {
  color: #8400ff;
}

h1.category-title.category-nature {
  color: #8baa2e;
}

h1.category-title.category-authors, h1.category-title.category-culture {
  color: #00aeef;
}

h1.category-title.category-video, h1.category-title.category-other, h1.category-title.category-mourrison, h1.category-title.category-comics {
  color: #ec058d;
}

.list-article-news {
  color: #fff;
  background-color: #303543;
  width: 100%;
  margin: 0;
  font-family: opensans, arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.list-article-news .article-news-time {
  color: #fc0;
  margin-bottom: 6px;
}

.list-article-news .article-news-content {
  align-items: flex-start;
  display: flex;
}

.list-article-news .article-news-content img.article-news-img {
  width: 65px;
  height: auto;
  margin-right: 12px;
}

.list-article-news .article-news-content a {
  color: #fc0;
}

.list-article-news .article-news-content .article-news-body-with-img, .list-article-news .article-news-content .article-news-body-no-img {
  width: auto;
}

.wrapper-latest-news-detail {
  color: #fff;
  background-color: #303543;
  margin: 32px 10px 28px;
  padding: 0 58px 32px;
  display: block;
  position: relative;
}

.wrapper-latest-news-detail .latest-news-title {
  text-transform: uppercase;
  height: 64px;
  padding-top: 6px;
  padding-left: 6px;
  font-family: opensans, arial, sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.wrapper-latest-news-detail .dataimg-flash {
  z-index: 5;
  width: 48px;
  height: 54px;
  position: absolute;
  top: -16px;
  left: -18px;
}

.wrapper-latest-news-detail .article-news-divider {
  border-bottom: 1px solid #656c81;
  margin: 0 -10px 27px 10px;
}

.wrapper-latest-news-detail .article-news-divider:after, .wrapper-latest-news-detail .article-news-divider:before {
  content: "";
  clear: both;
  display: table;
}

.wrapper-latest-news {
  color: #fff;
  background-color: #303543;
  width: 300px;
  height: 340px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.wrapper-latest-news .latest-news-title {
  text-transform: uppercase;
  height: 50px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
}

.wrapper-latest-news .latest-news-title a {
  color: #fff;
}

.wrapper-latest-news .latest-news-title a:hover {
  text-decoration: underline;
}

.wrapper-latest-news .latest-news-title .dataimg-flash {
  z-index: 5;
  width: 50px;
  height: 48px;
  position: absolute;
  top: -18px;
  left: -17px;
}

.wrapper-latest-news .latest-news-content-out .latest-news-content {
  -ms-overflow-style: none;
  height: 290px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.wrapper-latest-news .latest-news-content-out .latest-news-content.ps-container .ps-scrollbar-y-rail {
  opacity: .6;
  cursor: pointer;
  background-color: #303543;
  width: 11px;
  margin-right: 7px;
}

.wrapper-latest-news .latest-news-content-out .latest-news-content.ps-container .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #656c81;
  width: 7px;
}

.wrapper-latest-news .latest-news-content-out .latest-news-content.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y {
  width: 7px;
}

.wrapper-latest-news .latest-news-content-out .latest-news-content.ps-container:hover.ps-in-scrolling.ps-y .ps-scrollbar-y-rail {
  background-color: #a7a7c2;
}

.wrapper-latest-news .latest-news-content-out:after {
  content: "";
  z-index: 9;
  background-image: linear-gradient(to top, rgba(45, 53, 67, .9) 15%, rgba(45, 53, 67, .6) 45%, rgba(45, 53, 67, .3) 90%, rgba(45, 53, 67, .1));
  width: 275px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.wrapper-latest-news hr {
  background-color: #656c81;
  border-color: #656c81;
}

.mourrison-zasahuje {
  color: #232323;
  margin-bottom: 30px;
}

.mourrison-zasahuje:after, .mourrison-zasahuje:before {
  content: "";
  clear: both;
  display: table;
}

.mourrison-zasahuje .mourrison-box-header {
  margin-left: 15px;
}

.mourrison-zasahuje .mourrison-box-header svg {
  width: 100%;
  height: 55px;
}

.mourrison-zasahuje .mourrison-box-header svg image {
  width: 100%;
}

.mourrison-zasahuje .mourrison-box-header a {
  display: block;
}

.mourrison-zasahuje .mourrison-box-body {
  padding-left: 15px;
  position: relative;
}

.mourrison-zasahuje .mourrison-box-body a {
  position: absolute;
  right: 31.5px;
}

.mourrison-zasahuje h2 {
  margin: 16px 0 10px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

.mourrison-zasahuje span {
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 24px;
  display: block;
}

.mourrison-zasahuje a {
  color: #fc0;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.mourrison-zasahuje a:hover {
  text-decoration: underline;
}

.mourrison-zasahuje a .dataimg-arrow-right-orange {
  width: 12px;
  height: 100%;
  position: absolute;
  bottom: -1px;
  right: -24px;
}

.mobile .mourrison-zasahuje {
  width: 100%;
}

.mobile .mourrison-zasahuje .mourrison-box-header {
  margin-bottom: 1em;
  margin-left: 0;
}

.mobile .mourrison-zasahuje .mourrison-box-header a {
  width: 100%;
  display: block;
}

.mobile .mourrison-zasahuje .mourrison-box-header a img {
  width: 100%;
  height: auto;
}

.mobile .mourrison-zasahuje .mourrison-box-body {
  text-align: justify;
  padding-left: 0;
}

.mobile .mourrison-zasahuje .mourrison-box-body h2 {
  margin-top: initial;
}

.mobile .mourrison-zasahuje .mourrison-box-body span {
  margin-bottom: 1.1em;
  line-height: 1.5;
}

.mobile .mourrison-zasahuje .mourrison-box-body a {
  right: inherit;
  position: relative;
}

@media screen and (max-width: 620px) {
  .mobile .mourrison-zasahuje .mourrison-box-body {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 450px) {
  .mobile .mourrison-zasahuje .mourrison-box-body {
    font-size: 1.1em;
  }
}

.fb-comments {
  margin-bottom: 40px;
}

.article-title {
  margin: 0;
  padding: 0;
  font-size: 35px;
}

.article-right-listing {
  margin-top: 30px;
}

.article-right-listing .list-article-mini {
  margin-bottom: 30px;
}

.article-right-listing .category-sticker {
  margin-bottom: 20px;
}

.right-col {
  min-height: 4000px;
}

article {
  position: relative;
}

article .article-advertisement {
  width: 480px;
  margin-bottom: 30px;
  margin-left: 50px;
}

article .body a {
  text-decoration: none;
}

article .body a:hover {
  text-decoration: underline;
}

article .body figure {
  margin: 1em 0;
}

article .body span .description span {
  text-align: left;
}

article .body p:empty {
  height: 0;
  margin: 0;
  line-height: 0;
}

article .body h6 {
  width: calc(100% + 50px);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  left: -50px;
}

article .body .link-in-article {
  text-align: left;
}

article .body .link-in-article .list-article.list-article-medium {
  margin: 15px 0 45px;
}

article .body .link-in-article a, article .body .link-in-article a:hover {
  text-decoration: none;
}

article .body:after, article .body:before {
  content: "";
  clear: both;
  display: table;
}

article .gallery-info a.gallery-link, article .gallery-info a.gallery-link:hover span {
  text-decoration: none;
}

article.category-technics .body a, article.category-technics .gallery-info a span {
  color: #8400ff;
}

article.category-technics .gallery-photos-caption .dataimg-arrow-right svg path {
  fill: #8400ff;
}

article.category-nature .body a, article.category-nature .gallery-info a span {
  color: #8baa2e;
}

article.category-nature .gallery-photos-caption .dataimg-arrow-right svg path {
  fill: #8baa2e;
}

article.category-culture .body a, article.category-culture .gallery-info a span {
  color: #00aeef;
}

article.category-culture .gallery-photos-caption .dataimg-arrow-right svg path {
  fill: #00aeef;
}

article.category-video .body a, article.category-video .gallery-info a span {
  color: #ec058d;
}

article.category-video .gallery-photos-caption .dataimg-arrow-right svg path {
  fill: #ec058d;
}

article .link-in-article {
  width: calc(100% + 50px);
  position: relative;
  left: -50px;
}

article .link-in-article a {
  text-decoration: none;
}

article .link-in-article a h3 {
  font-weight: bold;
}

article .article-image {
  margin-top: 30px;
}

article .article-image img :not(vp-logo) {
  width: 620px;
  height: 335px;
}

article .article-image img {
  width: 100%;
  height: auto;
}

article .article-sharers-and-info {
  clear: both;
  margin-top: 20px;
  margin-left: 50px;
  position: relative;
}

article .article-sharers-and-info.joke-detail {
  margin-left: 0;
}

article .article-sharers-and-info.joke-detail:after, article .article-sharers-and-info.joke-detail:before {
  content: "";
  clear: both;
  display: table;
}

article .article-sharers-and-info .article-sharers {
  float: left;
  z-index: 10;
  position: relative;
}

article .article-sharers-and-info .article-sharers .article-sharer-title {
  color: #ff2400;
  margin-bottom: 13px;
  font-family: Gargle, Arial, sans-serif;
  font-size: 32px;
  display: inline-block;
  position: relative;
  transform: rotate(-3deg);
}

article .article-sharers-and-info .article-sharers .article-sharer-title .dataimg-arrow-new-edition {
  background-size: 34px 43px;
  width: 34px;
  height: 43px;
  position: absolute;
  top: 5px;
  right: -40px;
  transform: rotate(-20deg);
}

article .article-sharers-and-info .article-sharers .article-sharer-social-plugins .twitter-sharer {
  padding-left: 15px;
  display: inline-block;
}

article .article-sharers-and-info .article-info {
  color: #a7a7c2;
  text-align: right;
  padding-top: 56.5px;
  font-size: 13px;
  position: relative;
}

article .article-sharers-and-info .article-info p {
  margin: 0;
}

article .article-sharers-and-info .article-info p .article-date {
  font-weight: bold;
}

article .article-sharers-and-info .article-info p .article-author, article .article-sharers-and-info .article-info p .article-commercial {
  margin-left: 8px;
}

article .article-sharers-and-info .article-info p .article-archiv {
  margin-top: 5px;
  display: block;
}

article .article-sharers-and-info .article-info p .article-archiv a {
  color: #00aeef;
  font-weight: bold;
}

article .article-content {
  margin-top: 40px;
}

article .article-content .perex {
  margin-bottom: 25px;
  margin-left: 50px;
  font-size: 17px;
  font-weight: bold;
}

article .article-content .body {
  margin-left: 50px;
}

article h3 {
  font-size: 19px;
  font-weight: normal;
}

article .keywords {
  margin-top: 22px;
  margin-bottom: 40px;
}

article .related-articles {
  margin-bottom: 45px;
}

article .related-articles .related-articles-title {
  text-transform: uppercase;
  color: #303543;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
}

article .related-articles .related-articles-links {
  font-family: Arial, sans-serif;
}

article .related-articles .related-articles-links .related-articles-row {
  margin-top: 4px;
}

article .related-articles .related-articles-links a {
  color: #303543;
  font-weight: 700;
  text-decoration: none;
}

article .related-articles .related-articles-links a .dataimg-arrow-right-blue {
  vertical-align: middle;
  width: 17px;
  height: 19px;
  margin-left: 7px;
  display: inline-block;
}

article .related-articles .related-articles-links a:hover {
  text-decoration: underline;
}

article .image-in-article p {
  color: #a7a7c2;
  margin: 13px 0;
}

.page-footer {
  border-bottom: 1px solid #f1f1f6;
  margin-top: 60px;
  margin-bottom: 10px;
  padding-bottom: 25px;
}

.page-footer ul {
  text-align: center;
  color: #3c454d;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 19px;
  font-weight: bold;
  list-style-type: none;
  display: flex;
}

.page-footer ul li {
  margin: 0 10px;
  padding: 0;
}

.page-footer ul li a {
  color: #3c454d;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -3px rgba(0, 0, 0, 0);
}

.page-footer ul li a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.page-footer ul li a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -3px #3c454d;
}

.dropdown-label {
  text-transform: uppercase;
  font-family: opensans, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.list-year-issue {
  text-align: center;
}

.list-year-issue .title-archiv {
  text-align: left;
}

.list-year-issue .select-year-issue {
  height: 84px;
  margin: 0 0 32px;
  line-height: 84px;
}

.list-year-issue .edition-number {
  float: left;
  width: 164px;
  height: 248px;
  margin: 16px;
  position: relative;
}

.list-year-issue .edition-number img {
  width: 152px;
  height: 204px;
}

.list-year-issue .edition-number span {
  height: 38px;
  margin: auto;
  line-height: 38px;
}

form.user-select {
  text-align: center;
}

form.user-select select {
  height: 42px;
  display: inline-block;
}

form.user-select select[name="yearDropDown"] {
  visibility: hidden;
  width: 104.5px;
}

form.user-select select[name="issueDropDown"] {
  visibility: hidden;
  width: 132px;
  margin-left: 10px;
}

form.user-select:before, form.user-select:after {
  clear: both;
  content: "";
  display: block;
}

form.user-select div.select-year-issue {
  width: auto;
  display: inline-block;
}

.title-archiv-detail {
  text-align: center;
  border-top: 1px solid #f1f1f6;
  border-bottom: 1px solid #f1f1f6;
  margin: 16px 0;
  padding: 16px 0 24px;
}

.title-archiv-detail:before, .title-archiv-detail:after {
  clear: both;
  content: "";
  display: block;
}

.title-archiv-detail h1 {
  margin: 0 0 16px;
  font-size: 25px;
}

.mobile .list-year-issue .select-year-only-wrapper {
  height: 42px;
  margin: 18px auto;
  line-height: 42px;
}

.mobile .list-year-issue .select-year-only-wrapper:after, .mobile .list-year-issue .select-year-only-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.mobile .list-year-issue .select-year-only-wrapper .select-year-issue.with-label {
  height: 42px;
  line-height: 42px;
}

.mobile .list-year-issue .select-year-only-wrapper .select-year-issue.with-label label.dropdown-label {
  text-align: right;
  float: left;
  text-transform: uppercase;
  width: 48.5%;
  height: 100%;
  padding-right: 17px;
  font-family: opensans, arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
}

.mobile .list-year-issue .select-year-only-wrapper .select-year-issue.with-label .year.with-label {
  float: right;
  text-align: left;
  width: 48.5%;
  height: 100%;
}

.mobile .select-year-issue-wrapper {
  width: 100%;
  height: 42px;
  line-height: 42px;
}

.mobile .select-year-issue-wrapper:after, .mobile .select-year-issue-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.mobile .select-year-issue-wrapper .year {
  text-align: right;
  float: left;
  width: 48.5%;
  height: 100%;
  padding-right: 17px;
}

.mobile .select-year-issue-wrapper .select-issue-wrapper {
  float: right;
  text-align: left;
  width: 48.5%;
  height: 100%;
}

.mobile .select-year-issue-wrapper .select-issue-wrapper .select-issue {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 450px) {
  .mobile .select-year-issue-wrapper {
    width: 100%;
    height: 42px;
    line-height: 42px;
  }

  .mobile .select-year-issue-wrapper:after, .mobile .select-year-issue-wrapper:before {
    content: "";
    clear: both;
    display: table;
  }

  .mobile .select-year-issue-wrapper .year {
    text-align: right;
    float: left;
    width: 38.5%;
    height: 100%;
    padding-right: 2px;
  }

  .mobile .select-year-issue-wrapper .select-issue-wrapper {
    float: right;
    text-align: left;
    width: 60.5%;
    height: 100%;
  }

  .mobile .select-year-issue-wrapper .select-issue-wrapper .select-issue {
    width: 100%;
    height: 100%;
  }
}

.growsearch {
  text-align: center;
  z-index: 10;
  width: 35px;
  height: 40px;
  padding-top: 10px;
  display: inline-block;
  position: relative;
}

.growsearch .dataimg-search-shadow {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.growsearch .growsearch-hidden-form {
  background-color: #fff;
  position: absolute;
  top: -1px;
  right: -1px;
}

.growsearch .growsearch-hidden-form form {
  border: 1px solid #f1f1f6;
  padding: 10px 15px 10px 0;
  display: none;
}

.growsearch .growsearch-hidden-form form .growsearch-field {
  border: 0;
  outline: none;
  margin-left: 10px;
  margin-right: 30px;
  position: relative;
}

.growsearch .growsearch-hidden-form form .growsearch-field::placeholder {
  color: #a7a7c2;
  opacity: 1;
}

.growsearch .growsearch-hidden-form form .dataimg-search-shadow {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 15px;
}

.growsearch .growsearch-hidden-form form .growsearch-submit {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: none;
}

.list-komiks {
  box-sizing: border-box;
}

.list-komiks .title-section .category-sticker {
  text-align: left;
  height: 54px;
  padding: 0;
  font-size: 25px;
  line-height: 54px;
  display: block;
}

.list-komiks .title-section .category-sticker .list-article-medium-category-icon {
  width: 0;
}

.list-komiks .title-section .category-sticker p {
  margin-left: 16px;
}

.list-komiks .title-section .category-sticker:hover p {
  text-decoration: none;
}

.article-comics {
  margin-bottom: 40px;
}

.video-in-article {
  border-bottom: 1px solid #f1f1f6;
  width: calc(100% + 50px);
  position: relative;
  left: -50px;
}

.video-in-article p {
  color: #a7a7c2;
  margin: 13px 0;
}

.wikisearch-list {
  margin: 38px 0 32px;
}

.wikisearch-list .wikisearch-title {
  font-size: 16px;
  font-weight: normal;
}

.wikisearch-list .wikisearch-title h1 {
  font-family: arial;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

.wikisearch-list.mobile {
  margin: 38px 0 48px;
}

.wikisearch-list.mobile .wikisearch-title {
  font-size: 29px;
  line-height: 1.2;
}

.wikisearch-list.mobile .wikisearch-title h1 {
  font-size: 29px;
  line-height: 1.2;
  display: inline-block;
}

.wikisearch-list.mobile .list-article-medium-title {
  font-size: 29px;
  line-height: 1.2;
}

.wikisearch-list.mobile .sharer.sharer-horizontal {
  display: none;
}

.wikisearch-paging {
  margin-bottom: 38px;
}

.search-page {
  margin-bottom: 32px;
  position: relative;
}

.search-page .default-form {
  background-color: #f1f1f6;
  width: 100%;
  height: 40px;
  font-size: 17px;
  line-height: 40px;
}

.search-page .default-form .currency-input {
  color: #303543;
  background-color: #f1f1f6;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 0 30px 0 14px;
  font-weight: 400;
  display: inline-block;
}

.search-page .default-form .currency-input::placeholder {
  color: #a7a7c2;
  opacity: 1;
}

.search-page .default-form .main-button.button-search {
  z-index: 99;
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 10px;
}

.search-page .default-form .main-button.button-search .dataimg-search {
  width: 32px;
  height: 32px;
}

.search-page .wikisearch-title {
  font-size: 17px;
  font-weight: normal;
}

.search-page .wikisearch-title h1 {
  font-family: arial;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
}

.search-page input:focus {
  outline: none;
}

.search-page svg path {
  fill: #303543;
}

.wikisearch-title.mobile {
  font-size: 29px;
}

.wikisearch-title.mobile h1 {
  font-size: 29px;
  display: inline-block;
}

@media screen and (max-width: 620px) {
  .wikisearch-title.mobile {
    font-size: 1.3em;
  }

  .wikisearch-title.mobile h1 {
    margin-top: 3px;
    font-size: 1.3em;
    line-height: 1.3;
  }
}

@media screen and (max-width: 450px) {
  .wikisearch-title.mobile {
    font-size: 1.1em;
  }

  .wikisearch-title.mobile h1 {
    font-size: 1.1em;
    line-height: 1.1;
  }
}

.carousel-comics-margin {
  border-bottom: 1px solid #f1f1f6;
  margin: 25px 50px;
  padding-bottom: 55px;
  position: relative;
}

.carousel-comics-margin .comics-carousel-left {
  cursor: pointer;
  width: 28px;
  height: 32px;
  display: block;
  position: absolute;
  top: 33%;
  left: -32px;
}

.carousel-comics-margin .comics-carousel-right {
  cursor: pointer;
  width: 28px;
  height: 32px;
  display: block;
  position: absolute;
  top: 33%;
  right: -32px;
}

.carousel-comics-parts {
  height: 204px;
}

.carousel-comics-parts .owl-item .carousel-item {
  overflow: hidden;
}

.carousel-comics-parts .owl-item.actual .edition-number {
  border: 1px solid #ec058d;
  margin-top: 10px;
}

.carousel-comics-parts .carousel-link-item {
  width: 100%;
  margin-bottom: 10px;
}

.carousel-comics-parts .carousel-link-item.actual .edition-number {
  border: 1px solid #ec058d;
  margin-top: 10px;
}

.carousel-comics-parts .carousel-link-item .edition-number {
  width: 120px;
  height: 192px;
  margin-bottom: 10px;
}

.carousel-comics-parts .carousel-link-item .edition-number img {
  width: 108px;
  height: 150px;
}

.carousel-comics-parts .carousel-link-item .edition-number span {
  margin: 8px 0;
}

.carousel-comics-parts .carousel-link-item.selected .edition-number {
  border: 1px solid #ec058d;
  margin: 10px 0;
}

.carousel-comics-parts .owl-next, .carousel-comics-parts .owl-prev {
  cursor: pointer;
  width: 42px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.carousel-comics-parts .owl-next .comics-carousel, .carousel-comics-parts .owl-prev .comics-carousel {
  width: 28px;
  height: 32px;
  position: absolute;
  top: 78px;
}

.carousel-comics-parts .owl-next .comics-carousel path, .carousel-comics-parts .owl-prev .comics-carousel path {
  fill: #ec058d;
}

.carousel-comics-parts .owl-next .comics-carousel.right, .carousel-comics-parts .owl-prev .comics-carousel.right {
  left: 0;
}

.carousel-comics-parts .owl-next .comics-carousel.left, .carousel-comics-parts .owl-prev .comics-carousel.left {
  right: 0;
}

.carousel-comics-parts .owl-prev {
  left: -50px;
}

.carousel-comics-parts .owl-next {
  right: -50px;
}

.carousel-comics-detail-margin {
  margin: 0 60px 25px;
}

.carousel-comics-detail-margin .carousel-comics-detail {
  width: 840px;
  height: 1002px;
}

.carousel-comics-detail-margin .carousel-comics-detail .carousel-item-cover {
  align-content: center;
  align-items: center;
  width: 100%;
  min-height: 1000px;
  display: flex;
}

.carousel-comics-detail-margin .carousel-comics-detail .carousel-item-cover .carousel-item {
  text-align: center;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev {
  cursor: pointer;
  background: #fff;
  width: 50px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next:hover, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev:hover {
  background: #fff;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article {
  width: 52px;
  height: 94px;
  position: absolute;
  top: 460px;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article path, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article path {
  fill: #ec058d;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article.right, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article.right {
  left: 0;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article.right.nolinks, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article.right.nolinks {
  cursor: default;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article.left, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article.left {
  right: 0;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next .comics-article.left.nolinks, .carousel-comics-detail-margin .carousel-comics-detail .owl-prev .comics-article.left.nolinks {
  cursor: default;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-prev {
  left: 0;
}

.carousel-comics-detail-margin .carousel-comics-detail .owl-next {
  right: 0;
}

.article-title.comics {
  text-align: center;
  margin-bottom: 28px;
}

.article-sharers-and-info.comics-article {
  padding-left: 120px;
  padding-right: 120px;
}

.article-sharers-and-info.comics-article .article-info {
  color: #a7a7c2;
  padding-top: 14px;
  font-size: 14px;
  font-weight: 400;
}

.gallery-container {
  background-color: #303543;
  height: 100%;
}

.gallery-container .gallery-container {
  width: 1050px;
  height: 620px;
  margin: auto auto 60px;
}

.gallery-container .gallery-container .gallery-main-container div.image-description {
  background-color: #000;
  padding: 30px 10px;
}

@media screen and (max-width: 620px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    width: 50px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

.question-and-answer {
  color: #303543;
}

.category-mourrison, .category-mourrison .title-section {
  margin-bottom: 14px;
}

.category-mourrison .paging {
  border-top: 0;
}

.list-question {
  color: #303543;
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 24px;
  font-family: arial;
}

.list-question .author {
  color: #ec058d;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 700;
}

.list-question .title {
  margin: 0 0 28px;
  font-family: opensans, arial, sans-serif;
  font-size: 23px;
}

.list-question .text {
  margin-bottom: 22px;
}

.list-question .link {
  text-align: right;
  padding-bottom: 16px;
}

.list-question .link a {
  text-transform: uppercase;
  height: 42px;
  font-family: opensans, arial, sans-serif;
  display: inline-block;
}

.list-question.mobile {
  border-bottom: 0;
}

.list-question.mobile h2 {
  font-size: 29px;
  line-height: 1.2;
}

.list-question.mobile h2.mourrison-title {
  color: #ec058d;
}

.list-question.mobile h2.title {
  font-size: 24px;
  line-height: 1.2;
}

.list-question.mobile .link {
  text-align: center;
}

.discussion-under-question, .list-question-title {
  margin-bottom: 24px;
  line-height: 24px;
}

.discussion-under-question .title-section, .list-question-title .title-section {
  margin-bottom: 4px;
}

.discussion-under-question .title-section a, .list-question-title .title-section a {
  display: inline-block;
}

.discussion-under-question .title-section h2, .list-question-title .title-section h2 {
  height: 24px;
  margin-left: 2px;
  font-family: opensans, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.discussion-under-question .title-section .title-section-category-icon span, .list-question-title .title-section .title-section-category-icon span {
  height: 24px;
}

.discussion-under-question .title-section .title-section-category-icon span span, .list-question-title .title-section .title-section-category-icon span span {
  width: auto;
  height: 100%;
  display: inline-block;
}

.discussion-under-question {
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 96px;
}

.discussion-under-question .title-section {
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 64px;
}

.discussion-under-question .button-main {
  text-transform: uppercase;
  height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: opensans, arial, sans-serif;
}

.discussion-under-question .button-main svg {
  top: 6px;
}

.mourrison-question-answer {
  margin-bottom: 10px;
}

.mourrison-question-answer h2 {
  margin-top: 14px;
  margin-bottom: 29px;
  font-size: 29px;
  line-height: 1.2;
}

.mourrison-question-answer a {
  color: #ec058d;
}

.mourrison-question-answer a:hover {
  text-decoration: underline;
}

.mourrison-question-answer .author {
  color: #ec058d;
  font-size: 14px;
  font-weight: 700;
}

.mourrison-question-answer .question {
  margin-bottom: 48px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.4;
}

.mourrison-question-answer .answer {
  float: right;
  margin-bottom: 48px;
}

.mourrison-question-answer .answer .caption {
  font-weight: 700;
}

.mourrison-question-answer .answer.mobile {
  text-align: justify-all;
  position: relative;
}

.mourrison-question-answer .answer.mobile .icon-col {
  z-index: 5;
  width: 131px;
  height: 114px;
  position: absolute;
  top: 0;
  left: 0;
}

.mourrison-question-answer .answer.mobile .icon-col .icon {
  width: 100%;
  height: 100%;
}

.mourrison-question-answer .answer.mobile .answer-col {
  width: 100%;
  padding-left: 135px;
}

.mourrison-question-answer .article-sharers-and-info .article-info {
  padding-top: 52px;
  font-weight: 400;
}

.mourrison-question-answer .article-sharers-and-info .article-info.mobile {
  padding-top: 3em;
}

@media screen and (max-width: 620px) {
  .mourrison-question-answer h2 {
    font-size: 1.3em;
  }

  .mourrison-question-answer .answer.mobile .icon-col {
    z-index: 5;
    width: 110px;
    height: 96px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mourrison-question-answer .answer.mobile .icon-col .icon {
    width: 100%;
    height: 100%;
  }

  .mourrison-question-answer .answer.mobile .answer-col {
    padding-left: 114px;
  }

  .mourrison-question-answer .question {
    font-weight: 600;
  }
}

@media screen and (max-width: 450px) {
  .mourrison-question-answer h2 {
    font-size: 1.1em;
  }

  .mourrison-question-answer .answer.mobile .icon-col {
    z-index: 5;
    width: 100px;
    height: 87px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mourrison-question-answer .answer.mobile .icon-col .icon {
    width: 100%;
    height: 100%;
  }

  .mourrison-question-answer .answer.mobile .answer-col {
    padding-left: 104px;
  }
}

.form-ask-mourrison {
  box-sizing: border-box;
  margin-bottom: 50px;
  font-family: arial;
}

.form-ask-mourrison:after, .form-ask-mourrison:before {
  content: "";
  clear: both;
  display: table;
}

.form-ask-mourrison .intro {
  font-size: 17px;
  font-style: italic;
}

.form-ask-mourrison .intro p {
  padding-top: 14px;
}

.form-ask-mourrison .intro .img {
  margin-bottom: 32px;
}

.form-ask-mourrison .intro .img .icon-mourisson-dotaz {
  margin-left: 14px;
}

.form-ask-mourrison .intro:after, .form-ask-mourrison .intro:before {
  content: "";
  clear: both;
  display: table;
}

.form-ask-mourrison .login-link-wrapper {
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.form-ask-mourrison .login-link-wrapper:after, .form-ask-mourrison .login-link-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.form-ask-mourrison .login-link {
  text-transform: uppercase;
}

.form-ask-mourrison .info {
  padding-right: 0;
  font-family: opensans, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.form-ask-mourrison .login-mourris {
  padding-right: 0;
}

.form-ask-mourrison .login-mourris a {
  width: 100%;
  display: inline-block;
}

.form-ask-mourrison .form-container.send {
  display: none;
}

.form-ask-mourrison .question-form label {
  vertical-align: top;
  font-weight: 700;
}

.form-ask-mourrison .question-form textarea {
  width: 98%;
}

.form-ask-mourrison .question-form .margin-bottom {
  margin-bottom: 20px;
}

.form-ask-mourrison .question-form .button-main.button-right-arrow {
  text-transform: uppercase;
  padding-left: 38px;
  font-family: opensans, arial, sans-serif;
  font-weight: 700;
}

.mobile .form-ask-mourrison {
  margin-bottom: 1em;
}

.mobile .form-ask-mourrison:after, .mobile .form-ask-mourrison:before {
  content: "";
  clear: both;
  display: table;
}

.mobile .form-ask-mourrison .intro {
  border-bottom: 1px solid #f1f1f6;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.mobile .form-ask-mourrison .intro:after, .mobile .form-ask-mourrison .intro:before {
  content: "";
  clear: both;
  display: table;
}

.mobile .form-ask-mourrison .intro p {
  float: left;
  text-align: justify;
  width: 60.5%;
  padding-top: 0;
}

.mobile .form-ask-mourrison .intro .img {
  float: right;
  text-align: right;
  width: 38.5%;
}

.mobile .form-ask-mourrison .intro .img .icon {
  margin: 0 auto;
}

.mobile .form-ask-mourrison .login-link-wrapper {
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.mobile .form-ask-mourrison .login-link-wrapper:after, .mobile .form-ask-mourrison .login-link-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.mobile .form-ask-mourrison .login-link-wrapper .question-form .send-your-question {
  text-align: center;
}

.mobile .form-ask-mourrison .login-link-wrapper .question-form .send-your-question input.button-main.button-right-arrow {
  display: inline-block;
}

.mobile .form-ask-mourrison .login-link {
  text-transform: uppercase;
}

.mobile .form-ask-mourrison .info {
  text-align: center;
  margin-bottom: 1em;
  font-size: 17px;
}

.mobile .form-ask-mourrison .login-mourris {
  text-align: center;
  padding-right: 0;
}

.mobile .form-ask-mourrison .login-mourris a {
  width: inherit;
  display: inline-block;
}

.mobile .form-ask-mourrison .form-container.send {
  display: none;
}

.article-news {
  color: #303543;
  background-color: #303543;
  width: 100%;
  font-family: opensans, arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  position: relative;
}

.article-news .article-news-in {
  padding: 0 0 27px;
}

.article-news .article-news-time {
  color: #fc0;
  margin-bottom: 16px;
  font-size: 13px;
}

.article-news .article-news-content .article-news-body {
  color: #fff;
  margin-bottom: 6px;
  font-family: arial;
  font-size: 15px;
  font-weight: 400;
}

.article-news .article-news-content a {
  color: #fc0;
}

.article-news .article-news-content .article-news-sharer {
  padding-right: 0;
}

.article-news .article-news-content:after, .article-news .article-news-content:before {
  content: "";
  clear: both;
  display: table;
}

.form-send-joke {
  border-bottom: 1px solid #e2e2ea;
  margin-bottom: 30px;
}

.form-send-joke:after, .form-send-joke:before {
  content: "";
  clear: both;
  display: table;
}

.form-send-joke label {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

.form-send-joke input, .form-send-joke .captcha-img {
  margin-bottom: 28px;
}

.form-send-joke .captcha-img {
  float: left;
  margin-right: 16px;
}

.form-send-joke input.name:required, .form-send-joke input.captcha:required {
  color: #00aeef;
  border: 1px solid #00aeef;
}

.form-send-joke input.name:valid, .form-send-joke input.captcha:valid {
  color: #8796a5;
  border: 1px solid #dbe1e7;
}

.form-send-joke input.name:invalid, .form-send-joke input.captcha:invalid {
  color: #ec058d;
  border: 1px solid #ec058d;
}

.form-send-joke form.joke-form input.submit.tipsubmit.button-main.button-right-arrow {
  text-transform: uppercase;
  width: 100%;
}

.form-send-joke form.joke-form .subscription-field input, .form-send-joke form.joke-form .subscription-field textarea {
  color: #8796a5;
  border: 1px solid #dbe1e7;
  border-radius: 10px;
  outline: none;
  padding: 8px 10px;
  font-family: Arial, sans-serif;
}

.form-send-joke form.joke-form .input-btn-section {
  vertical-align: top;
  font-size: 0;
  display: inline-block;
}

.form-send-joke form.joke-form .input-btn-section .disable-input-field {
  vertical-align: top;
  cursor: pointer;
  background-color: #f0f0f0;
  margin-right: 10px;
  display: inline-block;
}

.form-send-joke form.joke-form .input-btn-section .file-upload {
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.form-send-joke form.joke-form .input-btn-section .file-upload:after, .form-send-joke form.joke-form .input-btn-section .file-upload:before {
  content: "";
  clear: both;
  display: table;
}

.form-send-joke form.joke-form .input-btn-section .file-upload:hover {
  cursor: pointer;
}

.form-send-joke form.joke-form .input-btn-section .file-upload .upload-btn {
  vertical-align: top;
  text-transform: uppercase;
  color: #9199af;
  cursor: pointer;
  text-shadow: 0 -2px #e2e2ea, 0 -1px #e2e2ea, 0 0 #e2e2ea, 2px -2px #e2e2ea, 2px -1px #e2e2ea, 2px 0 #e2e2ea, -2px -2px #e2e2ea, -2px -1px #e2e2ea, -2px 0 #e2e2ea, 1px -2px #e2e2ea, 1px -1px #e2e2ea, 1px 0 #e2e2ea, -1px -2px #e2e2ea, -1px -1px #e2e2ea, -1px 0 #e2e2ea, 0 -2px #e2e2ea, 0 -1px #e2e2ea, 0 0 #e2e2ea;
  background: #e2e2ea;
  border: 1px solid #dbe1e7;
  border-radius: 10px;
  height: 38px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 38px;
  text-decoration: none;
  transition: all .2s ease-in;
  display: inline-block;
  box-shadow: inset 0 -2px #e2e2ea, inset 0 -4px rgba(0, 0, 0, 0);
}

.form-send-joke form.joke-form .input-btn-section .file-upload .upload-btn::selection {
  color: #e2e2ea;
  text-shadow: none;
  background: #e8e8ee;
}

.form-send-joke form.joke-form .input-btn-section .file-upload .upload-btn:hover {
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #e2e2ea, inset 0 -4px #ec058d;
}

.form-send-joke form.joke-form .input-btn-section .file-upload input[type="file"].upload {
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity= 0);
  text-shadow: 0 -2px #e2e2ea, 0 -1px #e2e2ea, 0 0 #e2e2ea, 2px -2px #e2e2ea, 2px -1px #e2e2ea, 2px 0 #e2e2ea, -2px -2px #e2e2ea, -2px -1px #e2e2ea, -2px 0 #e2e2ea, 1px -2px #e2e2ea, 1px -1px #e2e2ea, 1px 0 #e2e2ea, -1px -2px #e2e2ea, -1px -1px #e2e2ea, -1px 0 #e2e2ea, 0 -2px #e2e2ea, 0 -1px #e2e2ea, 0 0 #e2e2ea;
  height: 38px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: all .2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 -2px #e2e2ea, inset 0 -4px rgba(0, 0, 0, 0);
}

.form-send-joke form.joke-form .input-btn-section .file-upload input[type="file"].upload::selection {
  color: #e2e2ea;
  text-shadow: none;
  background: #e8e8ee;
}

.form-send-joke form.joke-form .input-btn-section .file-upload input[type="file"].upload:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #e2e2ea, inset 0 -4px #ec058d;
}

.form-send-joke form.joke-form .input-btn-section .file-upload input[type="file"].upload:hover {
  cursor: pointer;
}

.send-joke-wrapper.mobile {
  margin: 0 12px;
}

.send-joke-wrapper.mobile .form-send-joke {
  border-bottom: 0;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field {
  margin-bottom: 1.3em;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field:after, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field:before {
  content: "";
  clear: both;
  display: table;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .input-btn-section, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field label, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field span {
  width: 100%;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .input-btn-section.label-title-joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .input-btn-section.label-name, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .input-btn-section .disable-input-field, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .joke.label-title-joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .joke.label-name, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .joke .disable-input-field, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field label.label-title-joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field label.label-name, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field label .disable-input-field, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field span.label-title-joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field span.label-name, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field span .disable-input-field {
  float: left;
  width: 42.5%;
  margin-right: 0;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .title-joke, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .name, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .captcha, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field .file-upload {
  float: right;
  width: 54.5%;
  margin-bottom: .2em;
  margin-right: 0;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field img, .send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field textarea {
  margin-bottom: 1em;
  margin-right: 0;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form .subscription-field label {
  font-size: 14px;
}

.send-joke-wrapper.mobile .form-send-joke form.joke-form input.submit.tipsubmit.button-main.button-right-arrow {
  width: 52.5%;
  margin: 0 auto;
  padding: 11px 36px;
}

.list-joke-mini .category-sticker {
  width: 100%;
  font-family: opensans, arial, sans-serif;
}

.list-joke-mini .category-sticker p {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.list-joke-mini .category-sticker p:hover {
  text-decoration: none;
}

.list-joke-mini li {
  width: auto;
  font-family: arial;
  font-weight: 700;
}

.list-joke-mini li a h3 {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  text-decoration: none;
  transition: all .2s ease-in;
  display: inline-block;
  box-shadow: inset 0 -1px #fff, inset 0 -3px rgba(0, 0, 0, 0);
}

.list-joke-mini li a h3::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-joke-mini li a h3:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -3px #656c81;
}

.list-joke-mini.mobile {
  border-top: 1px solid #f1f1f6;
  width: 98%;
  margin: 0 auto;
}

.list-joke-mini.mobile .list-joke-mini-content-header {
  padding-left: 5px;
}

.list-joke-mini.mobile .list-joke-mini-content-header h2 {
  color: #ff9c00;
}

.list-joke-mini.mobile ol {
  padding-left: 23px;
}

@media screen and (max-width: 620px) {
  .list-joke-mini.mobile .list-joke-mini-content-header h2 {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 450px) {
  .list-joke-mini.mobile .list-joke-mini-content-header h2 {
    font-size: 1.1em;
  }
}

.list-contestant {
  width: 300px;
  margin-bottom: 24px;
  margin-left: 0;
  margin-right: 0;
}

.list-contestant .contestant-title {
  color: #303543;
  text-align: left;
  height: 42px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.list-contestant .contestant-title .contestant-name {
  height: 28px;
}

.list-contestant .contestant-title .no-padding-right {
  margin-top: 2px;
  padding-right: 0;
}

.list-contestant .contestant-title label {
  text-align: left;
}

.list-contestant .contestant-title .url {
  z-index: 10;
  width: 48px;
  height: 20px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.list-contestant .contestant-title .url a {
  color: #00aeef;
  width: 48px;
  font-size: 15px;
  display: inline-block;
}

.list-contestant .contestant-title .url a .icon {
  z-index: 11;
  width: 10px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 0;
}

.list-contestant .contestant-img {
  max-width: 100%;
  margin-bottom: 16px;
}

.list-contestant .contestant-img.clickable {
  cursor: pointer;
}

.list-contestant .meta {
  border-bottom: 1px solid #f1f1f6;
}

.list-contestant .meta:after, .list-contestant .meta:before {
  content: "";
  clear: both;
  display: table;
}

.list-contestant .meta .result {
  color: #a7a7c2;
  margin-top: 18px;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
}

.list-contestant .meta .rank {
  text-align: right;
}

article.no-margin div.body {
  margin-left: 18px;
}

article.no-margin div.body h4, article.no-margin div.body p {
  margin-left: 36px;
  margin-right: 24px;
}

article.no-margin div.body .before-voting h4 {
  color: #ff9c00;
}

article.no-margin div.body .before-voting p {
  text-align: left;
  font-size: 14px;
}

article.no-margin div.body .votes-request {
  margin: 0 0 24px 36px;
}

article.no-margin div.body .captcha .captcha-text {
  margin-bottom: 12px;
  padding-left: 10px;
  font-weight: 400;
  display: block;
}

article.no-margin div.body .captcha .subscription-field {
  height: 34px;
  line-height: 34px;
}

article.no-margin div.body .captcha .subscription-field img {
  padding-top: 4px;
}

article.no-margin div.body .captcha .subscription-field .button-main {
  color: #fff;
}

article.mobile .questionary .votes-request {
  margin-bottom: 2em;
  font-size: 17px;
  line-height: 1.4;
}

article.mobile .list-contestant {
  width: 100%;
  margin-bottom: 2.8em;
}

article.mobile .list-contestant:after, article.mobile .list-contestant:before {
  content: "";
  clear: both;
  display: table;
}

article.mobile .list-contestant .photo {
  float: left;
  width: 300px;
  margin-left: -12px;
  margin-right: 1em;
}

article.mobile .list-contestant .comment.contestant-title {
  width: 98%;
  font-size: 22px;
  line-height: 1.3;
}

article.mobile .list-contestant .text.contestant-title {
  float: left;
  width: 46.5%;
  padding-top: .4em;
  font-size: 22px;
  line-height: 1.3;
  position: relative;
}

article.mobile .list-contestant .text.contestant-title:after, article.mobile .list-contestant .text.contestant-title:before {
  content: "";
  clear: both;
  display: table;
}

article.mobile .list-contestant .text.contestant-title .vote-check {
  float: left;
  width: 18px;
  height: 18px;
  display: block;
}

article.mobile .list-contestant .text.contestant-title label {
  float: right;
  width: 92%;
  display: block;
}

article.mobile .list-contestant .text.contestant-title .url {
  clear: both;
  float: none;
  height: 29px;
  margin-top: 2.4em;
  margin-left: 8%;
  line-height: 29px;
  display: block;
  position: relative;
}

article.mobile .list-contestant .text.contestant-title .url.result {
  margin-top: 1.1em;
  margin-left: 0;
}

article.mobile .list-contestant .text.contestant-title .url a {
  width: 56px;
  font-family: opensans, arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.3;
}

article.mobile .list-contestant .text.contestant-title .url a .icon {
  width: 15px;
  height: 30px;
  top: 0;
}

article.mobile .list-contestant .text.contestant-title .meta {
  border: 0;
}

article.mobile .list-contestant .text.contestant-title .meta .votes, article.mobile .list-contestant .text.contestant-title .meta .rank {
  text-align: left;
  margin-top: 0;
  padding-bottom: 0;
  font-size: 17px;
  line-height: 1.4;
}

article.mobile .captcha {
  border-top: 1px solid #f1f1f6;
  margin-bottom: 2em;
}

article.mobile .captcha:after, article.mobile .captcha:before {
  content: "";
  clear: both;
  display: table;
}

article.mobile .captcha span {
  margin: 1em 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  display: block;
}

article.mobile .captcha .subscription-field {
  height: 40px;
  line-height: 40px;
}

article.mobile .captcha .subscription-field:after, article.mobile .captcha .subscription-field:before {
  content: "";
  clear: both;
  display: table;
}

article.mobile .captcha .subscription-field img {
  float: left;
  width: 33.5%;
  margin-right: 1.5%;
}

article.mobile .captcha .subscription-field input[type="text"] {
  float: left;
  width: 33.5%;
  height: 40px;
  margin-right: .5%;
}

article.mobile .captcha .subscription-field .button-main {
  color: #fff;
  float: left;
  background-position: right 24px top 12px;
  width: 30.5%;
  height: 100%;
  padding-right: 50px;
  font-size: 22px;
}

@media screen and (max-width: 620px) {
  article.mobile .questionary .votes-request {
    margin-bottom: 2em;
    font-size: 17px;
    line-height: 1.4;
  }

  article.mobile .list-contestant {
    width: 100%;
    margin-bottom: 2.8em;
  }

  article.mobile .list-contestant:after, article.mobile .list-contestant:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .list-contestant .photo {
    float: left;
    width: 50.5%;
    margin-left: -12px;
  }

  article.mobile .list-contestant .comment.contestant-title {
    width: 98%;
    font-size: 17px;
    line-height: 1.4;
  }

  article.mobile .list-contestant .text.contestant-title {
    float: right;
    width: 46.5%;
    padding-top: .4em;
    font-size: 17px;
    line-height: 1.4;
    position: relative;
  }

  article.mobile .list-contestant .text.contestant-title:after, article.mobile .list-contestant .text.contestant-title:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .list-contestant .text.contestant-title .vote-check {
    float: left;
    width: 18px;
    height: 18px;
    display: block;
  }

  article.mobile .list-contestant .text.contestant-title label {
    float: right;
    width: 88%;
    display: block;
  }

  article.mobile .list-contestant .text.contestant-title .url {
    clear: both;
    float: none;
    height: 29px;
    margin-top: .6em;
    margin-left: 12%;
    line-height: 29px;
    display: block;
    position: relative;
  }

  article.mobile .list-contestant .text.contestant-title .url.vote {
    margin-top: 2em;
  }

  article.mobile .list-contestant .text.contestant-title .url a {
    width: 46px;
    font-size: 14px;
    line-height: 1.4;
  }

  article.mobile .list-contestant .text.contestant-title .url a .icon {
    width: 12px;
    height: 25px;
    top: 1px;
  }

  article.mobile .list-contestant .text.contestant-title .url.result {
    margin-top: .8em;
  }

  article.mobile .list-contestant .text.contestant-title .meta .votes, article.mobile .list-contestant .text.contestant-title .meta .rank {
    text-align: left;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  article.mobile .captcha {
    border-top: 1px solid #f1f1f6;
    margin-bottom: 2em;
  }

  article.mobile .captcha:after, article.mobile .captcha:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .captcha span {
    margin: 1em 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    display: block;
  }

  article.mobile .captcha .subscription-field {
    height: 40px;
    line-height: 40px;
  }

  article.mobile .captcha .subscription-field:after, article.mobile .captcha .subscription-field:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .captcha .subscription-field img {
    float: left;
    width: 33.5%;
    margin-right: 1.5%;
  }

  article.mobile .captcha .subscription-field input[type="text"] {
    float: left;
    width: 33.5%;
    height: 40px;
    margin-right: .5%;
  }

  article.mobile .captcha .subscription-field .button-main {
    color: #fff;
    float: left;
    background-position: right 14px top 10px;
    width: 30.5%;
    height: 100%;
    padding-right: 30px;
    font-size: 17px;
  }
}

@media screen and (max-width: 450px) {
  article.mobile .questionary .votes-request {
    margin-bottom: 2em;
    font-size: 17px;
    line-height: 1.3;
  }

  article.mobile .list-contestant {
    width: 100%;
    margin-bottom: 2.2em;
  }

  article.mobile .list-contestant:after, article.mobile .list-contestant:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .list-contestant .photo {
    float: left;
    width: 50.5%;
    margin-left: -12px;
  }

  article.mobile .list-contestant .comment.contestant-title {
    width: 98%;
    height: auto;
    font-size: 17px;
  }

  article.mobile .list-contestant .text.contestant-title {
    float: right;
    width: 47.5%;
    height: 100%;
    padding-top: 2px;
    font-size: 17px;
    line-height: 1.2;
    position: relative;
  }

  article.mobile .list-contestant .text.contestant-title:after, article.mobile .list-contestant .text.contestant-title:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .list-contestant .text.contestant-title .vote-check {
    float: left;
    width: 18px;
    height: 18px;
    display: block;
  }

  article.mobile .list-contestant .text.contestant-title label {
    float: right;
    width: 80%;
    display: block;
  }

  article.mobile .list-contestant .text.contestant-title .url {
    clear: both;
    float: none;
    height: 17px;
    margin-top: 2px;
    margin-left: 20%;
    display: block;
    position: relative;
  }

  article.mobile .list-contestant .text.contestant-title .url.vote a {
    margin-top: 1em;
  }

  article.mobile .list-contestant .text.contestant-title .url a {
    width: 42px;
    font-size: 14px;
  }

  article.mobile .list-contestant .text.contestant-title .url a .icon {
    width: 10px;
    height: 20px;
    top: 3px;
  }

  article.mobile .list-contestant .text.contestant-title .url.result {
    margin-left: 0;
  }

  article.mobile .list-contestant .text.contestant-title .meta {
    margin-top: 5px;
  }

  article.mobile .list-contestant .text.contestant-title .meta .votes, article.mobile .list-contestant .text.contestant-title .meta .rank {
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
  }

  article.mobile .list-contestant .text.contestant-title .meta .result {
    padding-bottom: 0;
  }

  article.mobile .captcha {
    border-top: 1px solid #f1f1f6;
    margin-bottom: 2em;
  }

  article.mobile .captcha:after, article.mobile .captcha:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .captcha span {
    margin: 1em 0;
    font-weight: 600;
    display: block;
  }

  article.mobile .captcha .subscription-field {
    height: 40px;
    line-height: 40px;
  }

  article.mobile .captcha .subscription-field:after, article.mobile .captcha .subscription-field:before {
    content: "";
    clear: both;
    display: table;
  }

  article.mobile .captcha .subscription-field img {
    float: left;
    width: 33.5%;
    margin-right: 1.5%;
  }

  article.mobile .captcha .subscription-field input[type="text"] {
    float: left;
    width: 33.5%;
    height: 40px;
    margin-right: .5%;
  }

  article.mobile .captcha .subscription-field .button-main {
    color: #fff;
    float: left;
    background-position: right 14px top 10px;
    width: 30.5%;
    height: 100%;
    padding-right: 30px;
    font-size: 14px;
  }
}

.list-image-link-small {
  color: #fff;
  text-align: center;
  float: left;
  background-color: #303543;
  width: 154px;
  font-family: opensans, arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.list-image-link-small:after, .list-image-link-small:before {
  content: "";
  clear: both;
  display: table;
}

.list-image-link-small a {
  color: #fff;
  text-shadow: 0 -2px #303543, 0 -1px #303543, 0 0 #303543, 2px -2px #303543, 2px -1px #303543, 2px 0 #303543, -2px -2px #303543, -2px -1px #303543, -2px 0 #303543, 1px -2px #303543, 1px -1px #303543, 1px 0 #303543, -1px -2px #303543, -1px -1px #303543, -1px 0 #303543, 0 -2px #303543, 0 -1px #303543, 0 0 #303543;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #303543, inset 0 -2px rgba(0, 0, 0, 0);
}

.list-image-link-small a::selection {
  color: #303543;
  text-shadow: none;
  background: #515971;
}

.list-image-link-small a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #303543, inset 0 -2px #c2c7d7;
}

.list-image-link-small .image {
  text-align: center;
  width: 120px;
  margin: 0 auto 12px;
}

.list-games-horizontal {
  color: #fff;
  background-color: #303543;
  margin-bottom: 12px;
  padding-bottom: 18px;
}

.list-games-horizontal:after, .list-games-horizontal:before {
  content: "";
  clear: both;
  display: table;
}

.header {
  color: #fff;
  text-transform: uppercase;
  background-color: #303543;
  height: 42px;
  margin-bottom: 0;
  padding-left: 60px;
  font-family: opensans, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 42px;
  position: relative;
}

.header:after, .header:before {
  content: "";
  clear: both;
  display: table;
}

.header .games-logo {
  z-index: 2;
  width: 30px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.header h4 {
  margin: 0;
}

.header h4 a {
  color: #fff;
  text-shadow: 0 -2px #303543, 0 -1px #303543, 0 0 #303543, 2px -2px #303543, 2px -1px #303543, 2px 0 #303543, -2px -2px #303543, -2px -1px #303543, -2px 0 #303543, 1px -2px #303543, 1px -1px #303543, 1px 0 #303543, -1px -2px #303543, -1px -1px #303543, -1px 0 #303543, 0 -2px #303543, 0 -1px #303543, 0 0 #303543;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #303543, inset 0 -2px rgba(0, 0, 0, 0);
}

.header h4 a::selection {
  color: #303543;
  text-shadow: none;
  background: #515971;
}

.header h4 a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #303543, inset 0 -2px #c2c7d7;
}

.discussion-under-article .no-posts {
  text-align: left;
  margin-top: 30px;
  font-family: OpenSans, sans-serif;
}

.discussion-article-buttons {
  clear: both;
  margin-top: 35px;
  margin-bottom: 45px;
  margin-left: 150px;
  overflow: auto;
}

.discussion-article-buttons .add-post-button {
  float: left;
}

.discussion-article-buttons .show-discussion-button {
  float: right;
}

.article-discussion {
  border-bottom: 1px solid #e2e2ea;
  padding-bottom: 25px;
}

.article-discussion h2 {
  color: #fff;
  text-transform: uppercase;
  background-color: #00aeef;
  background-position: right 20px top 6px;
  background-repeat: no-repeat;
  background-size: 14px 25px;
  border-bottom-right-radius: 18px;
  margin: 0;
  padding: 1px 0 4px 9px;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 21px;
  position: relative;
}

.article-discussion h2 span {
  width: 25px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.article-discussion h2 span svg path {
  fill: #fff;
}

.article-discussion .article-discussion-posts .discussion-post:first-child {
  border-top: 0;
}

.article-discussion .article-discussion-buttons {
  clear: both;
  padding-top: 29px;
  padding-left: 149px;
  overflow: auto;
}

.article-discussion .article-discussion-buttons .button-add-post {
  float: left;
}

.article-discussion .article-discussion-buttons .button-show-discussion {
  float: right;
}

.pattern-subscription-gift {
  height: 120px;
  margin-bottom: 30px;
  position: relative;
}

.pattern-subscription-gift:after, .pattern-subscription-gift:before {
  content: "";
  clear: both;
  display: table;
}

.pattern-subscription-gift .banner-header {
  z-index: 10;
  margin-bottom: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.pattern-subscription-gift .banner-header .logo-abc {
  width: 68px;
  height: 34px;
  margin-right: 10px;
  display: inline-block;
}

.pattern-subscription-gift .banner-header .logo-abc div {
  width: 100%;
  height: 100%;
}

.pattern-subscription-gift .banner-header .logo-order {
  width: 187px;
  height: 34px;
  margin-top: 4px;
  display: inline-block;
}

.pattern-subscription-gift .banner-header .logo-order div {
  width: 100%;
  height: 100%;
}

.pattern-subscription-gift .banner-content {
  height: 120px;
  line-height: 120px;
  position: relative;
}

.pattern-subscription-gift .banner-content .icon {
  z-index: 10;
  width: 12px;
  height: 20px;
  position: absolute;
  top: 63px;
  right: 20px;
}

.pattern-subscription-gift .banner-content .icon.text-icon {
  top: 16px;
}

.pattern-subscription-gift .banner-content .icon div {
  width: 100%;
  height: 100%;
}

.pattern-subscription-gift .banner-content .banner-text {
  color: #fff;
  text-transform: uppercase;
  background-color: #00aeef;
  height: 48px;
  margin-top: 48px;
  padding-left: 18px;
  line-height: 48px;
}

.pattern-subscription-gift .banner-content .banner-text span {
  text-shadow: 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef, 2px -2px #00aeef, 2px -1px #00aeef, 2px 0 #00aeef, -2px -2px #00aeef, -2px -1px #00aeef, -2px 0 #00aeef, 1px -2px #00aeef, 1px -1px #00aeef, 1px 0 #00aeef, -1px -2px #00aeef, -1px -1px #00aeef, -1px 0 #00aeef, 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef;
  font-family: arial;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #00aeef, inset 0 -2px rgba(0, 0, 0, 0);
}

.pattern-subscription-gift .banner-content .banner-text span::selection {
  color: #00aeef;
  text-shadow: none;
  background: #26c4ff;
}

.pattern-subscription-gift .banner-content .banner-text span:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #00aeef, inset 0 -2px #fff;
}

@media screen and (max-width: 620px) {
  .pattern-subscription-gift {
    width: 100%;
    height: 120px;
    max-height: 120px;
    position: relative;
  }

  .pattern-subscription-gift .banner-header {
    z-index: 10;
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pattern-subscription-gift .banner-header .logo-abc {
    width: 56px;
    height: 28px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pattern-subscription-gift .banner-header .logo-abc div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-header .logo-order {
    width: 154px;
    height: 28px;
    margin-top: 4px;
    position: absolute;
    top: 0;
    left: 60px;
  }

  .pattern-subscription-gift .banner-header .logo-order div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-content {
    width: 100%;
    max-height: 90px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pattern-subscription-gift .banner-content .icon {
    z-index: 10;
    width: 12px;
    height: 20px;
    position: absolute;
    top: 50px;
    right: 14px;
  }

  .pattern-subscription-gift .banner-content .icon.text-icon {
    top: 16px;
  }

  .pattern-subscription-gift .banner-content .icon div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-content .banner-text {
    color: #fff;
    text-transform: uppercase;
    background-color: #00aeef;
    height: 48px;
    margin-top: 48px;
    padding-left: 18px;
    line-height: 48px;
  }

  .pattern-subscription-gift .banner-content .banner-text span {
    text-shadow: 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef, 2px -2px #00aeef, 2px -1px #00aeef, 2px 0 #00aeef, -2px -2px #00aeef, -2px -1px #00aeef, -2px 0 #00aeef, 1px -2px #00aeef, 1px -1px #00aeef, 1px 0 #00aeef, -1px -2px #00aeef, -1px -1px #00aeef, -1px 0 #00aeef, 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef;
    font-family: arial;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s ease-in;
    box-shadow: inset 0 -1px #00aeef, inset 0 -2px rgba(0, 0, 0, 0);
  }

  .pattern-subscription-gift .banner-content .banner-text span::selection {
    color: #00aeef;
    text-shadow: none;
    background: #26c4ff;
  }

  .pattern-subscription-gift .banner-content .banner-text span:hover {
    text-decoration: none;
    transition: all .2s ease-out;
    box-shadow: inset 0 -1px #00aeef, inset 0 -2px #fff;
  }
}

@media screen and (max-width: 450px) {
  .pattern-subscription-gift {
    width: 100%;
    height: 80px;
    max-height: 80px;
    position: relative;
  }

  .pattern-subscription-gift .banner-header {
    z-index: 10;
    width: 100%;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pattern-subscription-gift .banner-header .logo-abc {
    width: 48px;
    height: 24px;
    margin-right: 6px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pattern-subscription-gift .banner-header .logo-abc div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-header .logo-order {
    width: 132px;
    height: 24px;
    margin-top: 3px;
    position: absolute;
    top: 0;
    left: 52px;
  }

  .pattern-subscription-gift .banner-header .logo-order div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-content {
    width: 100%;
    max-height: 64px;
    position: absolute;
    top: 4px;
    left: 0;
  }

  .pattern-subscription-gift .banner-content .icon {
    z-index: 10;
    width: 9px;
    height: 15px;
    position: absolute;
    top: 40px;
    right: 6px;
  }

  .pattern-subscription-gift .banner-content .icon.text-icon {
    top: 16px;
  }

  .pattern-subscription-gift .banner-content .icon div {
    width: 100%;
    height: 100%;
  }

  .pattern-subscription-gift .banner-content .banner-text {
    color: #fff;
    text-transform: uppercase;
    background-color: #00aeef;
    height: 28px;
    margin-top: 28px;
    padding-left: 12px;
    line-height: 28px;
  }

  .pattern-subscription-gift .banner-content .banner-text span {
    text-shadow: 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef, 2px -2px #00aeef, 2px -1px #00aeef, 2px 0 #00aeef, -2px -2px #00aeef, -2px -1px #00aeef, -2px 0 #00aeef, 1px -2px #00aeef, 1px -1px #00aeef, 1px 0 #00aeef, -1px -2px #00aeef, -1px -1px #00aeef, -1px 0 #00aeef, 0 -2px #00aeef, 0 -1px #00aeef, 0 0 #00aeef;
    font-family: arial;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    transition: all .2s ease-in;
    box-shadow: inset 0 -1px #00aeef, inset 0 -2px rgba(0, 0, 0, 0);
  }

  .pattern-subscription-gift .banner-content .banner-text span::selection {
    color: #00aeef;
    text-shadow: none;
    background: #26c4ff;
  }

  .pattern-subscription-gift .banner-content .banner-text span:hover {
    text-decoration: none;
    transition: all .2s ease-out;
    box-shadow: inset 0 -1px #00aeef, inset 0 -2px #fff;
  }
}

.list-author {
  height: auto;
  font-family: arial;
  font-weight: 400;
}

.list-author .photo {
  float: left;
  width: 130px;
  height: auto;
  padding-top: 10px;
  display: inline-block;
}

.list-author .photo__avatar {
  width: 100%;
  height: auto;
}

.list-author .desc {
  float: right;
  width: 480px;
}

.list-author .desc h1, .list-author .desc h2 {
  text-transform: uppercase;
  font-size: 17px;
}

.list-author .desc h1.female, .list-author .desc h2.female {
  color: #ec058d;
}

.list-author .desc h1.female a, .list-author .desc h2.female a {
  color: #ec058d;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.list-author .desc h1.female a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-author .desc h2.female a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-author .desc h1.female a:hover, .list-author .desc h2.female a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #ec058d;
}

.list-author .desc h1.male, .list-author .desc h2.male {
  color: #00aeef;
}

.list-author .desc h1.male a, .list-author .desc h2.male a {
  color: #00aeef;
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.list-author .desc h1.male a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-author .desc h2.male a::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

.list-author .desc h1.male a:hover, .list-author .desc h2.male a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #00aeef;
}

.list-author .desc p {
  font-size: 17px;
}

.list-author:after, .list-author:before {
  content: "";
  clear: both;
  display: table;
}

ul {
  padding-left: 0;
  list-style-type: none;
  display: block;
}

ul.list-author-articles, ul .list-author-articles {
  font-family: arial;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
}

ul.list-author-articles li, ul .list-author-articles li {
  display: block;
}

ul.list-author-articles li a, ul .list-author-articles li a {
  padding-right: 30px;
  position: relative;
}

ul.list-author-articles li a span, ul .list-author-articles li a span {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

ul.list-author-articles li a span::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

ul .list-author-articles li a span::selection {
  color: #fff;
  text-shadow: none;
  background: #fff;
}

ul.list-author-articles li a span:hover, ul .list-author-articles li a span:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #656c81;
}

ul.list-author-articles li .icon, ul .list-author-articles li .icon {
  z-index: 10;
  width: 12px;
  height: 18px;
  position: absolute;
  bottom: 2px;
  right: 0;
}

ul.list-author-articles li:after, ul.list-author-articles li:before, ul .list-author-articles li:after, ul .list-author-articles li:before {
  content: "";
  clear: both;
  display: table;
}

.author-profile-detail {
  margin-bottom: 30px;
  margin-left: 15px;
}

.author-profile-detail .list-author .desc {
  width: 435px;
}

.list-author-article {
  border-top: 1px solid #f0f0f0;
  margin-left: 30px;
}

.writer-list .title-section {
  margin-bottom: 30px;
}

.writer-list .text-section {
  margin-bottom: 20px;
}

.writer-list .author-profile-detail {
  margin-bottom: 30px;
}

.writer-detail {
  margin-top: 30px;
}

.writer-detail.mobile {
  margin-bottom: 30px;
}

.social_buttons {
  text-align: center;
  margin: 60px 0 30px;
}

.social_buttons a {
  margin-left: 20px;
  display: inline-block;
}

.mobile .list-author-article {
  margin-left: 0;
}

.mobile .list-author {
  padding-left: 17px;
}

.mobile .list-author .desc {
  float: left;
  width: 80%;
  max-width: 500px;
  padding: 1em 1.4em;
  position: relative;
}

.mobile .list-author .desc h1, .mobile .list-author .desc h2, .mobile .list-author .desc div {
  font-size: 17px;
  line-height: 24px;
}

.mobile .list-author .desc div {
  text-align: justify;
}

.mobile .list-author .desc span.more-2 {
  color: #9199af;
  text-transform: uppercase;
}

.mobile .list-author .desc a {
  height: 24px;
  padding-right: 28px;
  line-height: 24px;
  display: block;
  position: relative;
}

.mobile .list-author .desc .icon {
  width: 16px;
  height: 24px;
  position: absolute;
  bottom: 4px;
  right: 0;
}

.mobile .list-author .photo {
  float: left;
  width: 17.3%;
  max-width: 107px;
  height: auto;
  padding-top: 1.8em;
}

.mobile .list-author .list-author-article {
  padding-top: 30px;
}

.mobile .list-author:after, .mobile .list-author:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 620px) {
  .mobile .list-author {
    font-size: 1em;
  }

  .mobile .desc {
    padding-top: 0;
  }

  .mobile .desc h2 {
    margin-top: 0;
    font-size: 1.1em;
  }

  .mobile .desc .more-2 {
    font-size: 1em;
  }
}

@media screen and (max-width: 450px) {
  .mobile .list-author {
    clear: both;
    font-size: .8em;
  }

  .mobile .list-author .photo, .mobile .list-author .desc {
    padding-top: 0;
  }

  .mobile .list-author .desc h2 {
    margin-top: 0;
    font-size: 1em;
  }

  .mobile .list-author .desc .more-2 {
    font-size: .8em;
  }
}

.text-with-header-and-link {
  background-color: #f1f1f6;
  width: 100%;
  margin-bottom: 10px;
  padding: 12px 12px 12px 15px;
}

.text-with-header-and-link:after, .text-with-header-and-link:before {
  content: "";
  clear: both;
  display: table;
}

.text-with-header-and-link .text-header {
  text-align: center;
  height: 68px;
  line-height: 68px;
}

.text-with-header-and-link .text-header a {
  text-align: center;
  display: inline-block;
}

.text-with-header-and-link .text-header .banner {
  width: 166px;
  height: 63px;
}

.text-with-header-and-link .text-body {
  margin-bottom: 15px;
  font-family: arial;
  font-size: 15px;
  line-height: 24px;
}

.text-with-header-and-link .text-body h2 {
  margin-top: 0;
  font-size: 18px;
}

.text-with-header-and-link .text-link {
  text-align: right;
  position: relative;
}

.text-with-header-and-link .text-link a {
  color: #ffb600;
  padding-right: 20px;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
}

.text-with-header-and-link .text-link a .icon {
  z-index: 10;
  width: 12px;
  height: 18px;
  position: absolute;
  bottom: 0;
  right: -4px;
}

.dzouky-hp-mobile {
  background-color: #f1f1f6;
  margin-bottom: 1em;
  padding: 8px 17px;
}

.dzouky-hp-mobile:after, .dzouky-hp-mobile:before {
  content: "";
  clear: both;
  display: table;
}

.dzouky-hp-mobile .text-with-header-and-link {
  padding: 0;
}

.dzouky-hp-mobile .text-with-header-and-link .text-body {
  text-align: justify;
  padding: 0;
}

.dzouky-hp-mobile .text-with-header-and-link .text-link, .dzouky-hp-mobile .text-with-header-and-link .text-link a {
  text-align: left;
}

.dzouky-hp-mobile .text-with-header-and-link .text-link a .icon {
  display: inline-block;
  position: relative;
  bottom: -3px;
  right: -8px;
}

.banner-vertical {
  width: 300px;
  height: 148px;
  margin-bottom: 20px;
}

.banner-vertical .banner-img {
  width: 300px;
  height: 115px;
}

.banner-vertical .banner-txt {
  background-color: #8baa2e;
  width: 100%;
  height: 28px;
  padding: 0 8px;
  font-family: opensans, arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.banner-vertical .banner-txt a {
  color: #fff;
  text-shadow: 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e, 2px -2px #8baa2e, 2px -1px #8baa2e, 2px 0 #8baa2e, -2px -2px #8baa2e, -2px -1px #8baa2e, -2px 0 #8baa2e, 1px -2px #8baa2e, 1px -1px #8baa2e, 1px 0 #8baa2e, -1px -2px #8baa2e, -1px -1px #8baa2e, -1px 0 #8baa2e, 0 -2px #8baa2e, 0 -1px #8baa2e, 0 0 #8baa2e;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #8baa2e, inset 0 -2px rgba(0, 0, 0, 0);
}

.banner-vertical .banner-txt a::selection {
  color: #8baa2e;
  text-shadow: none;
  background: #abcd46;
}

.banner-vertical .banner-txt a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #8baa2e, inset 0 -2px #dceab1;
}

.banner-vertical .banner-txt a .icon {
  z-index: 10;
  width: 12px;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 8px;
}

.banner-vertical .banner-txt a .icon svg {
  width: 100%;
  height: 100%;
}

.mobile .banner-vertical {
  height: inherit;
  width: 100%;
}

.mobile .banner-vertical .banner-img {
  width: 100%;
  height: auto;
}

.mobile .banner-vertical .banner-txt {
  height: 55px;
  padding-left: 12px;
  font-size: 22px;
  font-weight: 700;
  line-height: 55px;
}

.mobile .banner-vertical .banner-txt a .icon {
  width: 20px;
  height: 30px;
  top: 12px;
  right: 12px;
}

@media screen and (max-width: 620px) {
  .mobile .banner-vertical {
    margin-bottom: 20px;
  }

  .mobile .banner-vertical .banner-txt {
    height: 42.5px;
    padding-left: 12px;
    font-size: 17px;
    font-weight: 700;
    line-height: 42.5px;
  }

  .mobile .banner-vertical .banner-txt a .icon {
    width: 18px;
    height: 24px;
    top: 8px;
    right: 12px;
  }
}

@media screen and (max-width: 450px) {
  .mobile .banner-vertical .banner-txt {
    height: 42.5px;
    padding-left: 12px;
    font-size: 17px;
    font-weight: 600;
    line-height: 42.5px;
  }

  .mobile .banner-vertical .banner-txt a .icon {
    width: 18px;
    height: 24px;
    top: 8px;
    right: 8px;
  }
}

.dropdown:after, .dropdown:before {
  content: "";
  clear: both;
  display: table;
}

.dropdown ul {
  transition: all .3s ease-in-out;
  display: inline-table;
}

.dropdown ul ul {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: #f1f1f6;
  transform: translateY(-30px);
}

.dropdown ul ul:hover > a {
  color: #303543;
  text-shadow: 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6, 2px -2px #f1f1f6, 2px -1px #f1f1f6, 2px 0 #f1f1f6, -2px -2px #f1f1f6, -2px -1px #f1f1f6, -2px 0 #f1f1f6, 1px -2px #f1f1f6, 1px -1px #f1f1f6, 1px 0 #f1f1f6, -1px -2px #f1f1f6, -1px -1px #f1f1f6, -1px 0 #f1f1f6, 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -4px rgba(0, 0, 0, 0);
}

.dropdown ul ul:hover > a::selection {
  color: #f1f1f6;
  text-shadow: none;
  background: #f4f4f8;
}

.dropdown ul ul:hover > a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -4px #303543;
}

.dropdown ul ul.last {
  width: 244px;
  right: 0;
}

.dropdown ul ul ul {
  margin-right: -2px;
  right: -100%;
  transform: translateY(-50px);
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: all .3s ease-in-out;
  transform: translateY(0);
}

.dropdown ul li.dropdown-show > ul {
  opacity: 1;
  z-index: 2000;
  visibility: visible;
  pointer-events: auto;
  transition: all .3s ease-in-out;
  display: block;
}

.dropdown ul li.dropdown-show > ul li ul {
  right: -100%;
}

.dropdown-menu {
  text-align: center;
  border-top: 1px solid #f1f1f6;
  margin: 0 auto;
  font-weight: 700;
}

.dropdown-menu:after, .dropdown-menu:before {
  content: "";
  clear: both;
  display: table;
}

.dropdown-menu a {
  height: auto;
}

.dropdown-menu ul {
  z-index: 11;
  text-align: left;
  padding: 0;
  list-style: none;
  position: relative;
}

.dropdown-menu li {
  float: left;
  z-index: 2000;
  display: block;
}

.dropdown-menu li a {
  color: #303543;
  cursor: pointer;
  height: auto;
  font-size: 22px;
  line-height: 1.3;
  text-decoration: none;
}

.dropdown-menu li a .item-icon {
  width: 26px;
  height: 14px;
  margin-left: 2px;
  display: inline-block;
}

.dropdown-menu li a .item-icon svg {
  width: 27px;
  height: 15px;
}

.dropdown-menu li a:hover {
  color: #303543;
  text-shadow: 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6, 2px -2px #f1f1f6, 2px -1px #f1f1f6, 2px 0 #f1f1f6, -2px -2px #f1f1f6, -2px -1px #f1f1f6, -2px 0 #f1f1f6, 1px -2px #f1f1f6, 1px -1px #f1f1f6, 1px 0 #f1f1f6, -1px -2px #f1f1f6, -1px -1px #f1f1f6, -1px 0 #f1f1f6, 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6;
  background-color: #f1f1f6;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -4px rgba(0, 0, 0, 0);
}

.dropdown-menu li a:hover::selection {
  color: #f1f1f6;
  text-shadow: none;
  background: #f4f4f8;
}

.dropdown-menu li a:hover:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -4px #303543;
}

.dropdown-menu li.active {
  background-color: #f1f1f6;
}

.dropdown-menu li.dropdown-title {
  height: 55px;
  padding: 20px 0 2px;
  position: relative;
}

.dropdown-menu li.dropdown-title:hover {
  background-color: #f1f1f6;
}

.dropdown-menu li.dropdown-title:hover > a {
  color: #303543;
}

.dropdown-menu li.dropdown-title > a, .dropdown-menu li.dropdown-title > span {
  box-sizing: border-box;
  z-index: 12;
  height: auto;
  margin: 0 14px;
  padding-bottom: 1px;
  font-family: opensans, arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  display: block;
  position: relative;
}

.dropdown-menu li.dropdown-title > a.image-hamburger {
  background: url("../../../../images/abicko/dist/svg/hamburger.svg") no-repeat;
  width: 19px;
  height: 12px;
  margin-top: 6px;
}

.dropdown-menu li.dropdown-title > a.image-hamburger:hover {
  text-shadow: none;
  box-shadow: none;
}

.dropdown-menu li.dropdown-title.no-childs > a, .dropdown-menu li.dropdown-title.no-childs > span {
  margin: 0 14px;
}

.dropdown-menu li.dropdown-show > ul li ul {
  background: #f1f1f6;
}

.dropdown-menu li.dropdown-show > ul li ul a {
  color: #303543;
}

.dropdown-menu li ul {
  z-index: 20000;
  background: #f1f1f6;
  width: 127px;
  padding: 0;
  position: absolute;
  top: 100%;
}

.dropdown-menu li ul.wide {
  width: 285px;
}

.dropdown-menu li ul.thin {
  width: 102px;
}

.dropdown-menu li ul li {
  float: none;
  font-size: 14px;
  position: relative;
}

.dropdown-menu li ul li a {
  text-shadow: 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6, 2px -2px #f1f1f6, 2px -1px #f1f1f6, 2px 0 #f1f1f6, -2px -2px #f1f1f6, -2px -1px #f1f1f6, -2px 0 #f1f1f6, 1px -2px #f1f1f6, 1px -1px #f1f1f6, 1px 0 #f1f1f6, -1px -2px #f1f1f6, -1px -1px #f1f1f6, -1px 0 #f1f1f6, 0 -2px #f1f1f6, 0 -1px #f1f1f6, 0 0 #f1f1f6;
  margin: 0 15px;
  padding: 10px 0 1px;
  font-family: opensans, arial, sans-serif;
  font-size: 18px;
  text-decoration: none;
  transition: all .2s ease-in;
  display: inline-block;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -3px rgba(0, 0, 0, 0);
}

.dropdown-menu li ul li a::selection {
  color: #f1f1f6;
  text-shadow: none;
  background: #f4f4f8;
}

.dropdown-menu li ul li a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -2px #f1f1f6, inset 0 -3px #303543;
}

.dropdown-menu li ul li:last-of-type a {
  margin-bottom: 20px;
}

.dropdown-menu li ul li:hover > a {
  color: #303543;
}

.dropdown-menu li ul li:hover ul {
  color: #303543;
  background: #f1f1f6;
  padding: 0;
  position: absolute;
  top: -1px;
  right: -100%;
}

.dropdown-menu li ul li:hover ul li a, .dropdown-menu li ul li:hover ul li:hover a {
  color: #303543;
}

.dropdown-menu.menu-others {
  border-top: 0;
  height: 49px;
  line-height: 49px;
  display: flex;
}

.dropdown-menu.menu-others > ul {
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
  display: flex;
}

.dropdown-menu.menu-others > ul li > a.image-hamburger {
  margin-top: 6px;
}

.dropdown-menu.menu-others ul {
  margin-top: 12px;
}

.dropdown-menu.menu-others li a {
  font-size: 18px;
}

.dropdown-menu.menu-others li a .item-icon {
  width: 22px;
  height: 11px;
  margin-left: 1px;
  display: inline-block;
}

.dropdown-menu.menu-others li a .item-icon svg {
  width: 24px;
  height: 12px;
}

.dropdown-menu.menu-others li.dropdown-title {
  float: none;
  height: 36px;
  padding-top: 7px;
}

.dropdown-menu.menu-others li.dropdown-title > a, .dropdown-menu.menu-others li.dropdown-title > span {
  margin: 0 10px;
  font-size: 18px;
}

.dropdown-menu.menu-others li ul {
  width: 95px;
  margin-top: 0;
}

.dropdown-menu.menu-others li ul.wide {
  width: 225px;
}

.dropdown-menu.menu-others li ul.thin {
  width: 85px;
}

.dropdown-menu.menu-others li ul.last {
  width: 192px;
  right: 0;
}

.dropdown-menu.menu-others li ul li, .dropdown-menu.menu-others li ul li a {
  font-size: 14px;
}

.pdf-in-art {
  text-align: center;
  width: 100%;
  height: 38px;
  margin: 2px;
}

.pdf-in-art .button-main.button-right-arrow {
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
}

.pdf-in-art .button-main.button-right-arrow span {
  top: 10px;
}

.pdf-in-art.mobile {
  height: 46px;
}

.pdf-in-art.mobile .button-main.button-right-arrow span {
  top: 12px;
}

.xml-article-listing .xml-article-listing__header {
  height: 70px;
  padding: 0 20px;
  position: relative;
}

.xml-article-listing .xml-article-listing__header--category {
  background-color: #00aeef;
  border-radius: 0 0 20px;
  height: 45px;
  margin-bottom: 20px;
  padding: 0 35px;
}

.xml-article-listing .xml-article-listing__header--category .xml-article-listing__icon {
  display: none;
}

.xml-article-listing .xml-article-listing__header--category .xml-article-listing__title {
  color: #fff;
  top: unset;
  left: unset;
  font-size: 15px;
  font-weight: bold;
  line-height: 45px;
  position: relative;
  transform: none;
}

.xml-article-listing .xml-article-listing__icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.xml-article-listing .xml-article-listing__icon svg {
  display: block;
}

.xml-article-listing .xml-article-listing__title {
  text-transform: uppercase;
  margin: 0;
  font-size: 22px;
  display: inline-block;
  position: absolute;
  top: 54%;
  left: 55px;
  transform: translate(0, -50%);
}

.xml-article-listing .list-article-mini-title {
  width: 100%;
  padding: 16px 15px 0;
  display: block;
}

.xml-article-listing .category-sticker {
  text-align: center;
  min-width: 120px;
  padding: 9px 0 9px 10px;
}

.xml-article-listing--vertical-col .list-article.list-article-mini {
  margin-bottom: 20px;
  position: relative;
}

.xml-article-listing--horizontal .xml-article-listing__list {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.xml-article-listing--horizontal .list-article.list-article-mini {
  height: auto;
  margin-bottom: 30px;
  position: relative;
}

.xml-article-listing img {
  display: block;
}

@media screen and (max-width: 1050px) {
  .xml-article-listing--horizontal .xml-article-listing__list {
    justify-content: space-around;
  }

  .xml-article-listing--horizontal .list-article.list-article-mini {
    height: auto;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 620px) {
  .xml-article-listing--horizontal .xml-article-listing__list {
    display: inline-block;
  }

  .xml-article-listing--horizontal .list-article.list-article-mini {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    display: inline-block;
  }

  .xml-article-listing--horizontal .list-article-image img {
    float: left;
    width: 48.5%;
    height: auto;
  }

  .xml-article-listing--horizontal .list-article-mini-title-holder {
    float: right;
    width: 46.5%;
    margin: 5px 1.5% 0 0;
  }

  .xml-article-listing--horizontal .list-article-mini-title-holder span {
    padding: 0;
    font-size: 1.3em;
    line-height: 1.3;
  }
}

@media screen and (max-width: 450px) {
  .xml-article-listing--horizontal .category-sticker, .xml-article-listing--vertical-col .category-sticker {
    max-width: 174px;
  }

  .xml-article-listing--horizontal .list-article-mini-title-holder span, .xml-article-listing--vertical-col .list-article-mini-title-holder span {
    font-size: 1em;
    line-height: 1.2;
  }
}

.instagram-box {
  filter: progid:dximagetransform. microsoft. gradient(startColorstr= "#f09433", endColorstr= "#bc1888", GradientType= 1);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  width: 100%;
  padding: 15px 8px;
}

.instagram-box__header {
  margin-bottom: 6px;
  font-family: opensans, arial, sans-serif;
  font-weight: 700;
  display: inline-block;
}

.instagram-box__header span {
  float: left;
}

.instagram-box__header-title {
  color: #fff;
  margin-left: 10px;
  font-size: 20px;
  line-height: 20px;
}

.instagram-box__container {
  background: #fff;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6px;
  display: flex;
}

.instagram-box .instagram-box__item {
  width: 33%;
  height: 33%;
  padding: 5px 4px;
  position: relative;
  overflow: hidden;
}

.instagram-box .instagram-box__item--hover {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.instagram-box figure {
  margin: 0;
}

.instagram-box figure img {
  opacity: 1;
  width: 100%;
  transition: all .3s ease-in-out;
  display: block;
}

.instagram-box figure:hover {
  background: #000;
}

.instagram-box figure:hover img {
  opacity: .5;
}

.instagram-box figure:hover .instagram-box__item--hover {
  display: block;
}

.instagram-box--right {
  width: 300px;
  height: 440px;
  margin-bottom: 20px;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.icon-premium {
  z-index: 1;
  box-sizing: border-box;
  color: #403014;
  text-align: center;
  background-color: #e8c157;
  width: 80px;
  height: 20px;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-premium__x {
  color: #e50031;
}

.icon-premium__fallback {
  font-family: Arial, sans-serif;
  line-height: 1;
}

.icon-premium__link {
  color: #403014;
  width: 100%;
  height: 100%;
}

.dataimg-icon-premium {
  z-index: 1;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.subscription {
  background: #f0f0f0;
  margin: 15px 0;
  position: relative;
}

.subscription.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(255, 255, 255, 0) 0 2%, #fff 60%, #fffdfd);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription__logo {
  background-size: contain;
  width: 190px;
  height: 115px;
  margin: 0 auto 15px;
}

.main-article .subscription__logo {
  width: 116px;
  height: 72px;
}

.subscription__logo.dataimg-isport-premium-welcome {
  width: 326px;
  height: 38px;
}

@media (max-width: 620px) {
  .subscription__logo {
    width: 150px;
    height: 92px;
  }
}

.subscription__headline {
  text-align: center;
  line-height: 40px;
  font-size: 30px !important;
}

.subscription__login {
  text-align: center;
  margin: 10px auto 25px;
  font-size: 13px;
  line-height: 22px;
}

.subscription__login a, .subscription__login-link {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__login-link:hover {
  text-decoration: none;
}

.subscription__login-link:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20viewBox%3D\"0%200%2022.1%2022.1\"%20><path%20fill%3D\"%23d21e1e\"%20d%3D\"M11%2C0C5%2C0%2C0%2C5%2C0%2C11c0%2C6.1%2C5%2C11%2C11%2C11s11-5%2C11-11C22.1%2C5%2C17.1%2C0%2C11%2C0z%20M16%2C19c-0.5-0.2-1-0.4-1.6-0.6c-1.1-0.4-1.2-1.1-1-2.7%20c0.5-0.6%2C0.8-1.3%2C1.1-2c0.1%2C0%2C0.1%2C0%2C0.2%2C0c0.6%2C0%2C0.6-0.6%2C0.7-0.8c0.3-0.6%2C0.4-1.6%2C0.1-2c-0.1-0.2-0.4-0.3-0.6-0.4%20c0-0.1%2C0-0.1%2C0.1-0.2c0.1-0.6%2C0.2-1.2%2C0.2-2c0-0.8-0.1-1.4-0.6-2c-0.8-1.2-3-1.7-4.1-1C9.8%2C4.6%2C7.6%2C5.3%2C7%2C6.7%20c-0.4%2C0.6-0.6%2C1.3-0.2%2C3.8c-0.2%2C0.1-0.4%2C0.2-0.5%2C0.5c-0.3%2C0.4%2C0.1%2C1.8%2C0.1%2C2c0%2C0.3%2C0.1%2C0.8%2C0.7%2C0.8c0.1%2C0%2C0.1%2C0%2C0.2%2C0%20c0.3%2C0.8%2C0.6%2C1.4%2C1.1%2C2c0.2%2C1.6%2C0.1%2C2.3-1%2C2.7c-0.6%2C0.2-1%2C0.4-1.4%2C0.5c-2.6-1.7-4.3-4.6-4.3-7.9c0-5.2%2C4.2-9.4%2C9.4-9.4%20c5.2%2C0%2C9.4%2C4.2%2C9.4%2C9.4C20.5%2C14.4%2C18.7%2C17.4%2C16%2C19z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  display: inline-block;
  position: relative;
  top: 7px;
  left: 7px;
}

.subscription__advantages {
  border-top: 1px solid #dcdcdc;
  margin: 25px 0 0;
  padding: 25px 0;
}

.subscription__advantages-headline {
  margin: 0 0 8px;
  font-family: arial;
  font-size: 18px;
  line-height: 21px;
  display: none;
}

.subscription__advantages-bullets {
  width: 300px;
  margin: 0 auto;
  padding: 0;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.subscription__advantages-bullet {
  background: url("/images/isportcz/dist/svg_fallback/advantage.png") 0 11px no-repeat;
  padding-left: 25px;
  line-height: 32px;
  list-style-type: none;
}

.subscription__types {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.subscription__type {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border: 0 solid #d1d4e4;
  flex: 0 0 48%;
  padding: 15px 15px 70px;
  list-style-type: none;
  position: relative;
}

.subscription__type-title {
  text-align: center;
  font-family: arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  display: block;
}

.subscription__type-price {
  color: #d21e1e;
  text-align: center;
  border-bottom: 1px solid #d1d4e4;
  margin: 8px 0 15px;
  padding-bottom: 20px;
  font-family: arial;
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  display: block;
}

.subscription__type-note small {
  color: #1e1e1e;
  font-family: arial;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 20px !important;
}

.subscription__type-order {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  width: calc(100% - 30px);
  height: 40px;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.subscription__type-order:hover {
  color: #fff;
}

.subscription__type-note {
  margin: 0;
  font-size: 14px !important;
  line-height: 24px !important;
}

.subscription__type-highlight {
  color: #403014;
  background: #e8c157;
  padding: 0 8px;
  font-weight: 700;
  display: inline-block;
}

.subscription__type:last-child:before {
  content: "Nejvýhodnější";
  color: #403014;
  background: #e8c157;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 700;
  display: none;
  position: absolute;
  top: -20px;
  left: -5px;
  transform: rotate(-5deg);
}

.subscription__payment-options {
  justify-content: space-between;
  margin: 30px 0 0;
  display: flex;
}

.subscription__payment-options-headline {
  min-width: 130px;
  margin: 8px 0 0;
  font-family: arial;
  line-height: 20px;
  display: block;
  font-size: 13px !important;
}

.subscription__payment-options-bullets {
  width: 465px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 22px;
  overflow: hidden;
}

.subscription__payment-option {
  float: left;
  background: #fff;
  border-right: 0 solid #d1d4e4;
  margin: 0 10px 0 0;
  padding: 6px 15px;
  list-style-type: none;
  display: flex;
}

.subscription__payment-option:last-child {
  border: none;
  margin: 0;
}

.subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

.subscription__help {
  color: #2c2f3b;
  content: "?";
  text-align: center;
  background: #d1d4e4;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.subscription__help:hover {
  cursor: help;
}

.subscription__help:hover:after {
  content: attr(data-title);
  text-align: left;
  background: #fcf7db;
  width: 250px;
  padding: 15px;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  bottom: 30px;
  left: -125px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.subscription__help:hover:before {
  z-index: 2;
  content: "";
  filter: drop-shadow(0 5px 3px rgba(0, 0, 0, .16));
  border-top: 10px solid #fcf7db;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  bottom: 22px;
  left: 0;
}

.dataimg-icon-premium-master-card {
  width: 26px;
}

.dataimg-icon-premium-visa {
  width: 29px;
}

.dataimg-icon-premium-gopay {
  width: 50px;
}

.dataimg-icon-premium-sms {
  width: 19px;
}

.subscription__corporate {
  border-top: 1px solid #dcdcdc;
  justify-content: space-between;
  margin: 20px 0;
  padding-top: 20px;
  display: flex;
}

.subscription__corporate-title {
  min-width: 130px;
  margin: 0;
  font-family: arial;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.subscription__corporate-note {
  text-align: left;
  width: 465px;
  margin: 0;
  font-family: arial;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

.subscription__corporate-link {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__type-voucher {
  background: #fff;
  width: 640px;
  min-height: 167px;
  margin: 20px auto;
  padding: 15px 5px 0 50%;
  position: relative;
  overflow: hidden;
}

.subscription__type-voucher .subscription__type-price {
  border: 0;
  margin: 10px;
  padding: 0;
}

.subscription__type-voucher .subscription__type-order {
  width: 100%;
  margin: 0;
  position: static;
}

.subscription__coupon-voucher {
  width: calc(50% - 15px);
  margin: 0;
  position: absolute;
  bottom: 7px;
  left: 15px;
  transform: rotate(-10deg);
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, .25);
}

.subscription--new.subscription--690 {
  width: 690px;
  margin-left: -50px;
}

.subscription--new .subscription__toggle {
  margin: 20px 0 30px;
  text-decoration: none;
}

.subscription--new .subscription__toggle:after {
  margin: 0;
}

.subscription--new .subscription__toggle.active:after {
  transform: rotate(180deg);
}

.subscription--new .subscription__toggle-button {
  background: none;
  border: 0;
  text-decoration: underline;
}

.subscription--new .subscription__toggle-button:hover {
  text-decoration: none;
}

.subscription--new .subscription__toggle-button:focus {
  outline: none;
}

.subscription--new .welcome {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: -3px;
  font-size: 31px;
  font-weight: bold;
  line-height: 37px;
  display: block;
}

.subscription--new .subscription__sub-headline {
  margin: 30px auto 20px;
  font-family: Roboto-Bold, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.subscription--new .subscription__logo {
  background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: 190px;
  width: 116px;
  height: 62px;
  margin-bottom: 22px;
}

.subscription--new .subscription__login {
  margin: 10px 0 40px;
  font-size: 14px;
  line-height: 28px;
}

.subscription--new .subscription__login:after {
  top: 5px;
}

.subscription--new .subscription__login-link {
  font-weight: normal;
}

.subscription--new .subscription__login-link.no-icon:after {
  content: "";
  display: none;
}

.subscription--new .subscription__buttons {
  text-align: center;
  margin: 35px 0 40px;
}

.subscription--new .subscription__button {
  color: #403014;
  background: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 2px;
  height: 28px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
}

.subscription--new .subscription__button.active {
  color: #d21e1e;
  border: 1px solid #d21e1e;
}

.subscription--new .subscription__button:first-child {
  margin-right: 7px;
}

.subscription--new .subscription__button:focus {
  outline: 0;
}

.subscription--new .subscription__types {
  align-items: flex-start;
}

.subscription--new .subscription__type {
  margin: 0 0 30px;
  padding: 30px 0;
}

.subscription--new .subscription__type.id-2 {
  min-width: 93%;
  margin: 0 auto 30px;
}

.subscription--new .subscription__type:last-child {
  margin-bottom: 0;
}

.subscription--new .subscription__type-order {
  width: 150px;
  margin: 26px auto 0;
  line-height: 38px;
  position: relative;
  bottom: 0;
  left: 0;
}

.subscription--new .subscription__type-order.buttonOff {
  color: red;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid red;
  font-weight: 400;
}

.subscription--new .subscription__type-order.buttonOff:hover {
  color: #fff;
}

.subscription--new .subscription__type-order:focus {
  outline: 0;
}

.subscription--new .subscription__type-order:hover {
  background: #a81818;
  text-decoration: none;
}

.subscription--new .subscription__coupon-voucher {
  bottom: 40px;
}

.subscription--new .subscription__type-price {
  color: #1e1e1e;
  border: none;
  margin: 15px 0 2px;
  padding: 0;
}

.subscription--new .subscription__type-price-month {
  color: #1e1e1e;
  margin: 0 0 0 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.subscription--new .subscription__type-perks {
  text-align: left;
  margin: 12px 0 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.subscription--new .subscription__type-perk {
  color: #403014;
  margin: 0 0 13px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  display: block;
  position: relative;
}

.subscription--new .subscription__type-perk:before {
  content: " ";
  background: url("/images/isportcz/dist/svg_fallback/advantage.png") 0 11px no-repeat;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  bottom: 1px;
  left: 0;
}

.subscription--new .subscription__type-perk.disadvantage:before {
  content: "❌";
  background: none;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: -2px;
}

.subscription--new .subscription__type-perk:last-child {
  margin: 0;
}

.subscription--new .subscription__type-title {
  margin: 0 0 12px;
  font-size: 24px;
  line-height: 28px;
}

.subscription--new .subscription__type-subtitle {
  text-align: center;
  color: #1e1e1e;
  margin: -8px 0 18px;
  font-family: Roboto Light, sans-serif;
  font-size: 24px;
  line-height: 28px;
  display: block;
}

.subscription--new .subscription__payment-options-headline {
  font-size: 14px !important;
}

.subscription--new .subscription__corporate-title, .subscription--new .subscription__corporate-note, .subscription--new .subscription__advantages-bullet {
  font-size: 14px;
}

.subscription--new .subscription__corporate {
  border: 0;
}

.subscription--new .subscription__payment-options {
  width: 93%;
  margin: 30px auto 50px;
}

.subscription--new .subscription__advantages-headline {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.subscription--new .subscription__advantages-bullet {
  margin-bottom: 5px;
  font-weight: normal;
}

.subscription--new .subscription__advantages-bullets {
  text-align: left;
  margin: 0;
}

.subscription--new .subscription__advantages {
  border: 0;
  margin: 50px 0 0;
  padding: 0;
  display: inline-block;
}

.subscription--new .subscription__advantages-wrapper {
  text-align: center;
}

.subscription--new .subscription__advantages-footnote {
  text-align: center;
  width: 50%;
  margin: 35px auto 50px;
  font-size: 14px;
  line-height: 18px;
}

.subscription--new .subscription__advantages-footnote a {
  color: #d21e1e;
  text-decoration: underline;
}

.subscription--new .subscription__advantages-footnote a:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-minimal {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  display: flex;
}

.subscription--new .subscription__type-minimal:first-child {
  margin-bottom: 10px;
}

.subscription--new .subscription__type-minimal-link {
  color: red;
  text-decoration: underline;
}

.subscription--new .subscription__type-minimal-link:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-extra {
  box-sizing: border-box;
  text-align: center;
  background: #e7c257;
  border: 1px solid #d9af2b;
  border-radius: 50%;
  width: 71px;
  height: 71px;
  padding: 5px;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  top: -25px;
  right: -10px;
}

.subscription--new .subscription__type-extra strong {
  color: #fff;
  margin-top: -1px;
  font-family: Roboto-Bold, sans-serif;
  font-size: 20px;
  line-height: 26px;
  display: block;
}

#paid-zone .subscription__note {
  width: 47%;
}

#paid-zone #activation-login .table-default td {
  border: 0;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #activation-login .table-default td.valid {
  font-weight: 700;
}

#paid-zone #activation-login .table-default td:first-child {
  width: 50%;
  font-weight: 500;
}

#paid-zone #activation-login .table-default td + td {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #activation-login .table-default td + td span.red {
  color: #d21e1e;
}

#paid-zone #activation-login .table-default td + td span.green {
  color: #65c41e;
}

#paid-zone #activation-login .table-default.summary, #paid-zone #activation-login .table-default.business {
  border-top: 1px solid #dcdcdc;
  margin: 0 0 12px;
}

#paid-zone #activation-login .table-default.summary td, #paid-zone #activation-login .table-default.business td {
  padding-top: 28px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #activation-login .table-default.summary td:first-child, #paid-zone #activation-login .table-default.business td:first-child {
  width: 45%;
  font-weight: 500;
}

#paid-zone #activation-login .table-default.business {
  margin-bottom: 5px;
}

#paid-zone #activation-login .table-default.valid-to tr:first-child td {
  border-top: 0;
  padding: 0 0 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

#paid-zone #activation-login .table-default.valid-to tr:first-child td.valid {
  font-weight: 700;
}

#paid-zone #activation-login .table-default.valid-to tr:last-child {
  padding-bottom: 12px;
}

#paid-zone #activation-login .table-default.valid-to.recurring-cancelled-true tr:last-child td {
  padding: 0 0 12px;
}

#paid-zone #activation-login .table-default.recurring td {
  padding: 0;
}

#paid-zone #activation-login .user-bonuses + .table-default {
  border: none;
}

#paid-zone #login-wrapper .main-container {
  margin-top: 60px;
}

#paid-zone .link-basic a {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
}

#paid-zone .link-basic a:hover {
  text-decoration: none;
}

#paid-zone .profile-info {
  margin-bottom: 30px;
  padding: 0 15px 15px;
}

#paid-zone .profile-info.headline {
  margin-bottom: 0;
  padding: 15px;
}

#paid-zone .profile-info.headline > h1 {
  color: #d21e1e;
  font-family: arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone .profile-info .main-button.cancel-recurring {
  color: #1e1e1e;
  background: none;
  width: auto;
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;
  position: relative;
  top: 0;
  right: 0;
}

#paid-zone .profile-info .main-button.cancel-recurring:hover {
  text-decoration: none;
}

#paid-zone .profile-info .change-business-term {
  text-align: right;
  margin-bottom: 15px;
}

#paid-zone .profile-info .change-business-term a {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
}

#paid-zone .profile-info .change-business-term a:hover {
  text-decoration: none;
}

#paid-zone .logout {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 24px;
  text-decoration: underline;
  position: absolute;
  top: 15px;
  right: 15px;
}

#paid-zone .profile-info.prihlaseni-info {
  text-align: left;
  margin: 0;
  padding: 15px 15px 30px;
  font-family: arial;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .active-until {
  text-align: right;
  margin-top: 3px;
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone .active-until .underline {
  color: #1e1e1e;
  margin-top: 12px;
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
}

#paid-zone .active-until .underline:hover {
  text-decoration: none;
}

#paid-zone #order-subscription {
  padding: 15px;
}

#paid-zone #order-subscription .well-content {
  background: #eef4f7;
  width: 64%;
}

#paid-zone #order-subscription .well-content p {
  padding: 15px;
}

#paid-zone #order-subscription .subscription-notice p {
  font-size: 13px;
}

#paid-zone #order-subscription .main-info h1 {
  color: #e50031;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone #order-subscription .logout {
  top: 0;
  right: 0;
}

#paid-zone #order-subscription .profile-info.prihlaseni-info {
  border-bottom: 1px solid #dcdcdc;
  margin: 0 0 25px;
  padding: 30px 0;
}

#paid-zone #order-subscription .table-default td {
  border: 0;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default td.valid {
  font-weight: 700;
}

#paid-zone #order-subscription .table-default td:first-child {
  width: 45%;
  font-weight: 500;
}

#paid-zone #order-subscription .table-default td + td {
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default td small {
  padding-right: 330px;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

#paid-zone #order-subscription .table-default.total {
  border: 0;
  margin: 0;
}

#paid-zone #order-subscription .table-default.total.sms-payment tr td {
  padding: 0 0 10px;
}

#paid-zone #order-subscription .table-default.total.sms-payment label {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default.total.sms-payment label .grey {
  color: #aaa;
}

#paid-zone #order-subscription .table-default.total tr.subscription__info {
  color: #2c2f3b;
  background: #fcf7db;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default.total tr.subscription__info td {
  text-align: left;
  padding: 10px;
  font-size: 12px;
}

#paid-zone #order-subscription .order-description {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 13px;
}

#paid-zone #order-subscription .subscription__total-price {
  border-bottom: 1px solid #dcdcdc;
}

#paid-zone #order-subscription .subscription__total-price tr td {
  padding-bottom: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone #order-subscription .subscription__total-price tr td:first-child {
  font-weight: 500;
}

#paid-zone #order-subscription .default-form.agreement {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0 30px;
}

#paid-zone #order-subscription .default-form.agreement .form-field label {
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .form-field label .underline {
  color: #1e1e1e;
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .personal__data {
  color: #1e1e1e;
  text-align: left;
  margin-left: 30px;
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .personal__data a {
  color: #1e1e1e;
  text-decoration: underline;
}

#paid-zone #order-subscription .default-form.agreement .personal__data a:hover {
  color: #1e1e1e;
  text-decoration: none;
}

#paid-zone #order-subscription .default-form.agreement .error-message {
  color: #d21e1e;
  text-align: left;
  margin: 10px;
  font-size: 13px;
  line-height: 18px;
  display: block;
}

#paid-zone #order-subscription .default-form .triangle-arrow--right {
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid #1e1e1e;
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  top: 0;
  left: 3px;
}

#paid-zone #order-subscription-sms {
  padding: 15px;
}

#paid-zone #order-subscription-sms .main-info h1 {
  color: #d21e1e;
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 0 30px;
  padding: 0 0 30px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone #order-subscription-sms #steps {
  border-bottom: 1px solid #dcdcdc;
  margin: 30px 0;
  padding: 0 0 30px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription-sms #steps li form {
  margin-top: 20px;
}

#paid-zone #order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 13px;
  line-height: 18px;
  top: 36px;
}

#paid-zone #order-subscription-sms #steps li .main-button {
  margin: 6px 0 0;
  font-size: 15px;
  line-height: 20px;
}

#paid-zone #order-subscription-sms #steps li .sms_input {
  float: left;
  border: 1px solid #d1d4e4;
  width: 218px;
  margin: 24px 10px 0 0;
  padding: 0 0 0 44px;
  font-size: 15px;
}

#paid-zone #order-subscription-sms #additional-info p {
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription-sms #additional-info h2 {
  margin: 30px 0 17px;
  font-size: 14px;
  line-height: 19px;
}

#paid-zone #order-subscription-sms #additional-info ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

#paid-zone #order-subscription-sms #additional-info ul li {
  margin: 0;
  font-size: 13px;
  line-height: 24px;
}

#paid-zone #order-subscription-sms #additional-info ul li:before {
  content: "";
  color: #d1d4e4;
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  display: inline-block;
}

#paid-zone #order-subscription-sms #additional-info .cancel-sub {
  background: #fcf7db;
  border: 0;
  margin: 30px 0;
  padding: 10px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .main-button {
  padding: 10px;
  font-size: 15px;
  line-height: 20px;
}

#paid-zone .subscription__voucher .order__success-title {
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

#paid-zone .subscription__voucher .order__success-title h2 {
  color: #e50031;
  font-size: 18px;
  line-height: 22px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  display: block;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: #d0112b;
  width: 293px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font: 700 15px / 25px Roboto, sans-serif;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font: 15px / 25px Roboto, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
  font-family: Roboto, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 15px;
  line-height: 22px;
}

#paid-zone .subscription__voucher .order__success-contact {
  visibility: visible;
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 13px;
  line-height: 21px;
}

form.default-form input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 2px solid #e6e6e6;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  transition: all .3s;
  position: absolute;
  top: 8px;
  left: 0;
}

form.default-form input[type="radio"]:checked + label:after {
  background: #1e1e1e;
  width: 8px;
  height: 8px;
  top: 13px;
  left: 5px;
}

#paid-zone #order-subscription input[type="submit"] {
  width: 220px;
}

form.default-form [type="checkbox"] + label:before {
  content: "";
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 6px;
  left: 0;
}

form.default-form [type="checkbox"] + label:after {
  content: "✔";
  color: #1e1e1e;
  opacity: 0;
  font-size: 16px;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 8px;
  left: 2px;
  transform: scale(0);
}

#isport-archive.wrapper {
  max-width: 1050px;
}

#isport-archive .choose-subscription {
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto 20px;
  padding: 10px 15px;
}

#isport-archive .subscription {
  width: 100%;
  margin: 20px auto;
}

#isport-archive .wrapper {
  max-width: 1050px;
}

#isport-archive #archive-container {
  width: 100%;
}

.subscription__toggle {
  text-align: center;
  color: #d21e1e;
  cursor: pointer;
  margin: 10px 0 25px;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-decoration: underline;
  display: block;
}

.subscription__toggle:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20viewBox%3D\"0%200%209%205\"%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20><path%20fill%3D\"%23d21e1e\"%20d%3D\"M4.6%2C5L9%2C1.3L8.3%2C0L4.5%2C3.1L0.8%2C0L0%2C1.3L4.3%2C5H4.6z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 13px;
  height: 7px;
  margin-left: 5px;
  transition: all .5s;
  display: inline-block;
}

.subscription__toggle.open:after {
  transform: rotate(180deg);
}

.subscription__faq {
  clear: both;
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: 30px auto 35px;
  padding: 15px;
}

.subscription__faq-title {
  margin-bottom: 20px;
  font-family: arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.subscription__faq-questions p {
  font-size: 14px;
  line-height: 18px;
}

.subscription__faq-questions p.subscription__faq-question {
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
  margin: 10px 0 0;
  padding: 0 30px 10px 0;
  line-height: 23px;
  position: relative;
}

.subscription__faq-questions p.subscription__faq-question.opened {
  border-bottom: 0;
}

.subscription__faq-questions p.subscription__faq-question.opened:after {
  transform: rotate(180deg);
}

.subscription__faq-questions p.subscription__faq-question:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20viewBox%3D\"0%200%209%205\"%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20><path%20fill%3D\"%23000000\"%20d%3D\"M4.6%2C5L9%2C1.3L8.3%2C0L4.5%2C3.1L0.8%2C0L0%2C1.3L4.3%2C5H4.6z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 12px;
  height: 6px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.subscription__faq-questions p:not(.subscription__faq-question) {
  display: none;
}

.subscription__faq-questions p.last {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.subscription__faq-footer {
  text-align: center;
  margin-top: 15px;
  font-size: 15px;
  line-height: 22px;
}

.subscription__faq-contact {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.subscription__faq-mail {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__faq-mail:hover {
  text-decoration: none;
}

.splash-faq.splash-product {
  padding: 0;
}

.splash-faq__question {
  width: 100%;
  display: inline-block;
}

.subscription--article {
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 590px;
  padding: 35px 50px;
}

@media (max-width: 750px) {
  .subscription--article {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.subscription--article .subscription__main-title--article {
  text-align: center;
  color: #fff;
  letter-spacing: 1px;
  background: #cca550;
  width: 154px;
  height: 46px;
  margin: -5px auto -1px;
  padding: 6px 0 0;
  font-family: Arial Narrow, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px !important;
}

.subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 29px;
  font-size: 22px !important;
}

.subscription--article .subscription__description {
  font-size: 16px;
  line-height: 1.3;
}

.subscription--article .subscription__type-order {
  background-color: #f66533;
  border: none;
  width: 190px;
  margin: 30px 15px 0 0;
  line-height: 40px;
  display: inline-block;
  position: static;
}

.subscription--article .subscription__button--link {
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
}

@media (max-width: 500px) {
  .subscription--article .subscription__button--link {
    margin-top: 5px;
    display: block;
  }
}

.subscription--article .subscription__button--link:hover {
  text-decoration: none;
}

.subscription--article .subscription__login {
  color: #fff;
  text-align: left;
  margin: 15px 0 0;
  font-size: 12px;
  line-height: normal;
}

@media (max-width: 500px) {
  .subscription--article .subscription__login {
    margin-top: 5px;
  }
}

.subscription--article .subscription__login a {
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
}

.subscription--article .subscription__login a:hover {
  text-decoration: none;
}

.subscription--article .subscription__login-link:after {
  display: none;
}

.subscription--article .subscription__logo {
  width: 100%;
  height: auto;
}

.subscription--article .subscription__logo svg {
  max-height: 20px;
}

.subscription--article .subscription__logo svg:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

.subscription--article .subscription__logo svg:last-child {
  opacity: .6;
  width: 45px;
}

.sticker-premium {
  z-index: 5;
  background-color: #1f1e26;
  background-image: url("https://img2.cncenter.cz/images/abicko/dist/svg/sticker-premium-abc.svg");
  background-repeat: no-repeat;
  background-size: 95px;
  border-top-right-radius: 18px;
  width: 95px;
  height: 38px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 800px) {
  .sticker-premium {
    width: 120px;
    height: 37px;
  }

  .sticker-premium:after {
    margin-top: 9px;
  }
}

.sticker-premium--article {
  width: 95px;
  height: 38px;
  margin-bottom: 15px;
  position: relative;
}

.sticker-premium--article .sticker-premium {
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
}

.sticker-premium--article .sticker-premium:after {
  margin-top: 10px;
}

@media (max-width: 800px) {
  .sticker-premium--article {
    margin-left: 12px;
  }
}

